import PersonLink from "./PersonLink";
import VehicleLink from "./VehicleLink";
class TagHistoryRecord {
    constructor(item) {
        this.occurred = new Date(item.occurred);
        this.status = item.status;
        this.person = item.person && new PersonLink(item.person);
        this.vehicle = item.vehicle && new VehicleLink(item.vehicle);
        this.user = item.user;
        this.modifiedBy = item.modifiedBy && new PersonLink(item.modifiedBy);
    }
}
export default TagHistoryRecord;
