import Versioned from "./Versioned";
import ZoneExtended from "./ZoneExtended";
export default class Equipment extends Versioned {
    constructor(data) {
        super(data);
        this.name = data.name;
        this.inspectorsNumber = data.inspectorsNumber;
        this.zone = data.zone && new ZoneExtended(data.zone);
    }
}
