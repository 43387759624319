import { isUserCatalogEditingDisabled } from "@dwclient/config";
const EN_CONTROL_RESOURCES = {
    columnValueHolder: "Search in table",
    searchPlaceholder: "Search",
    users: {
        title: "Users",
        unbindDialog: {
            title: "Are you sure you want to unlink the person?",
            close: "Cancel",
            ok: "Unlink",
        },
        bindDialog: {
            title: "Link person",
            ok: "Link",
        },
        restoreDialog: {
            singleOperationTitle: "Are you sure you want to restore the user?",
            multipleOperationTitle: "Are you sure you want to restore the selected users?",
            ok: "Restore",
            close: "Cancel",
        },
        archiveDialog: {
            singleOperationTitle: "Are you sure you want to archive the user?",
            multipleOperationTitle: "Are you sure you want to archive the selected users?",
            ok: "Archive",
            close: "Cancel",
        },
        form: {
            userBusy: "The selected person is associated with another user.",
            fields: {
                roles: "Assigned roles",
                login: "Login",
                password: "Password",
                person: "Linked person",
                source: "Authentication method",
            },
            submitNew: "Add",
            submitOld: "Save",
            titleNew: "New user",
            titleOld: "User",
        },
        filter: {
            hintTop: `by logins, ${isUserCatalogEditingDisabled ? "" : "roles, "}persons, organizations,`,
            hintBottom: "departments and positions",
            roleId: "Role",
            person: "Employee",
            login: "Login",
            statuses: {
                label: "Status",
                values: {
                    active: "Active",
                    archived: "Archived",
                },
            },
        },
        table: {
            showRoles: "Show",
            hideRoles: "Hide",
            setPerson: "Link",
            unsetPerson: "Unlink person",
            columns: {
                login: "Login",
                person: "Person",
                roles: "Roles",
                organization: "Organization",
                department: "Department",
                deployment: "Deployment",
            },
            newUserButton: "Add user",
        },
        operations: {
            archive: "Archive",
            restore: "Restore",
        },
    },
    filter: {
        emptySelect: "None",
        search: {
            placeholder: {
                search: "Search",
                hintTop: "participant(s), organization,",
                hintBottom: "departments and devices",
            },
        },
    },
    organizations: {
        title: "Organization structure",
        restoreSelectionDialog: {
            title: "Are you sure you want to restore the selected catalog items?",
            ok: "Restore",
            close: "Cancel",
        },
        restoreOrganizationDialog: {
            singleOperationTitle: "Are you sure you want to restore the organization?",
            multipleOperationTitle: "Are you sure you want to restore the selected organizations?",
            ok: "Restore",
            close: "Cancel",
        },
        restoreDepartmentDialog: {
            singleOperationTitle: "Are you sure you want to restore the department?",
            multipleOperationTitle: "Are you sure you want to restore the selected departments?",
            ok: "Restore",
            close: "Cancel",
        },
        restoreDeploymentDialog: {
            singleOperationTitle: "Are you sure you want to restore the deployment?",
            multipleOperationTitle: "Are you sure you want to restore the selected deployments?",
            ok: "Restore",
            close: "Cancel",
        },
        archiveOrganizationDialog: {
            singleOperationTitle: "Are you sure you want to archive the organization?",
            multipleOperationTitle: "Are you sure you want to restore the selected organizations?",
            ok: "Archive",
            close: "Cancel",
        },
        archiveDepartmentDialog: {
            singleOperationTitle: "Are you sure you want to archive the department?",
            multipleOperationTitle: "Are you sure you want to restore the selected departments?",
            ok: "Archive",
            close: "Cancel",
        },
        archiveDeploymentDialog: {
            singleOperationTitle: "Are you sure you want to archive the deployment?",
            multipleOperationTitle: "Are you sure you want to restore the selected deployments?",
            ok: "Archive",
            close: "Cancel",
        },
        messageDialog: {
            ok: "Understandably",
            organizationCannotBeArchived: "You cannot archive an organization that has persons",
            departmentCannotBeArchived: "You cannot archive a department that has persons",
            deploymentCannotBeArchived: "You cannot archive a deployment to which persons are appointed",
        },
        operations: {
            filter: "Filters",
            import: "Import",
            create: "Add organization",
            search: "Search",
            selectOrganization: "Organization",
            selectDepartment: "Department",
            hide: "Hide",
            show: "Show",
            edit: "Edit",
            showOrganizationDetails: "Details",
            showDepartmentDetails: "Details",
            showDeploymentDetails: "Details",
            delete: "Archive",
            cancel: "Cancel",
            clearSelection: "Deselect all",
            deleteSelected: "Delete selected",
            save: "Save",
            add: "Add",
            addDeployment: "Create deployment",
            restore: "Restore",
            addPerson: "Add person",
            gotoEmployeesList: "Go to the list of employees",
        },
        filter: {
            departmentName: "Department",
            deploymentId: "Deployment",
            organizationName: "Organization",
            hintTop: "by organizations, departments",
            hintBottom: "and deployments",
            status: {
                all: "All",
                active: "Active",
                archived: "Archived",
            },
            buttons: {
                clear: "Clear filter",
            },
        },
        fields: {
            organizationFull: "Organizations structure (departments num.)",
            department: "Department",
            positions: "Positions",
            employees: "Persons",
            position: "Deployments",
            positionName: "Name",
            synchronization: "Sync.",
            email: "E-mail",
            phone: "Phone",
            color: "Color",
            employee: "Employee",
            organization: "Name",
            address: "Address",
            web: "Web site",
            peculiarities: "Deployment features",
            synchronizationFull: "Synchronization",
        },
        bindDialog: {
            title: "Appoint as",
            ok: "Confirm",
            person: "Person",
        },
        organization: "Organization",
        department: "Department",
        newOrganization: "New organization",
        newDepartment: "New department",
        deployment: "Deployment",
        newDeployment: "New deployment",
        departmentDeleteMessage: "Are you sure you want to delete department?",
        deploymentDeleteMessage: "Are you sure you want to delete deployment?",
        organizationDeleteMessage: "Are you sure you want to delete department?",
        addDepartmentsMessage: "You can add departments and employees after creating an organization",
        rowsSelected: "Selected rows count",
    },
    devices: {
        title: "Wearable devices",
        menu: {
            editButton: "Edit",
            detailsButton: "Details",
            archiveButton: "Archive",
            restoreButton: "Restore",
            historyButton: "Device history",
        },
        issueDialog: {
            title: "Distribute",
            ok: "Distribute",
            forceOk: "Distribute anyway",
            trackable: "Select a person or vehicle",
            personWithoutDeployment: "The person is not assigned to the deployment",
            operationDateTime: "Date and time",
        },
        releaseDialog: {
            cancel: "Cancel",
            forceOk: "Return anyway",
            ok: "Return",
            description: "Are you sure you want to release device? You can additionally set release reason",
            status: "Status",
            title: "Return wearable device",
            operationDateTime: "Date and time",
            validation: {
                deviceIsPermanent: "Device is permanent",
            },
        },
        deleteDialog: {
            ok: "Archive",
            close: "Cancel",
            singleItemTitle: "Are you sure you want to archive a wearable device?",
            multipleItemTitle: "Are you sure you want to archive selected wearable devices?",
        },
        restoreDialog: {
            ok: "Restore",
            close: "Cancel",
            singleItemTitle: "Are you sure you want to restore a wearable device?",
            multipleItemTitle: "Are you sure you want to restore selected wearable devices?",
        },
        tagHistoryDialog: {
            title: "Device history",
            columns: {
                employee: "Employee",
            },
        },
        tagSelectorDialog: {
            findPlaceholder: "Find by name or UID",
        },
        messageDialog: {
            archiveErrorMessage: 'You cannot archive a wearable devices with the "Issued" status',
            ok: "Understandably",
        },
        fastDeviceIssuingDialog: {
            title: "Prompt distribution",
            deviceNotFound: "Wearable device is not registered with the system",
            personNotFound: "Person not found",
            deviceNotSelected: "Not selected",
            personNotSelected: "Not selected",
            personField: "Person",
            devicesField: "Wearable device",
            cancel: "Close",
            connectionStatus: "Connection",
            issue: "Distribute",
            reset: "Cancel",
            submissionError: "Unable to issue all devices",
        },
        deviceScannerDialog: {
            hint: "Bring the scanner to the the wearable device",
            cancel: "Cancel",
        },
        issueTypeFilter: {
            title: "Show permanent devices",
        },
        table: {
            columns: {
                status: "Status",
                lastModifiedAt: "Last changed at",
                lastModifiedBy: "Changed by",
                name: "Name",
                vendorId: "Device UID",
                trackable: "Related",
            },
            deviceScannerButton: "Use a scanner",
            promptDistributionButton: "Prompt distribution",
            deviceData: "Device data",
            setPerson: "Issue device",
            selection: "Selected",
            resetSelectionButton: "Reset selection",
            deleteButton: "Archive",
            restoreButton: "Restore",
            filter: {
                status: "Status",
                placeholder: "Search",
                hintTop: "by models, employees,",
                hintBottom: "names and devices",
                tagTypeId: "Model",
                trackable: "Employee",
                name: "Name",
                vendorId: "Device UID",
                buttons: {
                    clear: "Clear filter",
                },
            },
            createReportButton: "Create report",
            newDeviceButton: "Add device",
        },
        form: {
            newDeviceDialogTitle: "New device",
            save: "Save",
            create: "Add",
            fields: {
                tagTypeId: "Model",
                externalSystemId: "External system",
                name: "Name",
                vendorId: "Device UID",
                status: "Status",
                tagProfileId: "Profile SIZ",
            },
        },
    },
    employees: {
        title: "Employees",
        search: {
            placeholder: "Search",
            hintTop: "by employee, organization,",
            hintBottom: "department and position",
            columnSearch: "Search in table",
            name: "Employee name",
            deployment: "Deployment",
            position: "Position",
            department: "Department",
            organization: "Organization",
            buttons: {
                clear: "Clear filter",
            },
        },
        filter: {
            statuses: {
                label: "Status",
                values: {
                    appointed: "Appointed",
                    unassigned: "Unassigned",
                    archived: "Archived",
                },
            },
        },
        editEmployeeDialog: {
            addEmployeeTitle: "New person",
            editEmployeeTitle: "Person",
            addButton: "Add",
            editButton: "Save",
        },
        operations: {
            filter: "Filters",
            import: "Import",
            create: "Add person",
            add: "Add",
            forceAdd: "Add anyway",
            save: "Save",
            ok: "OK",
            loadPhoto: "Add photo",
            delete: "Delete",
            edit: "Edit",
            showDetails: "Details",
            fire: "Dismiss",
            restore: "Restore",
            cancel: "Cancel",
            clearSelection: "Deselect all",
            fireSelected: "Dismiss",
            restoreSelected: "Restore",
            unlink: "Unlink",
        },
        fields: {
            firstName: "First name",
            lastName: "Last name",
            middleName: "Middle name",
            organization: "Organization",
            department: "Department",
            position: "Deployment",
            devices: "Wearable devices",
            addDevice: "Add wearable devices",
            synchronization: "Sync.",
            email: "E-mail",
            phone: "Phone",
            employee: "Employee",
            model: "Model",
            primaryChannel: "Primary message channel",
            externalId: "Personnel Number",
            gender: "Gender",
            birthDate: "Date of birth",
            lastHealthCheck: "Date of medical examination",
            schedule: "Schedule",
            age: "years old",
        },
        fileSizeError: "File size must not exceed 1 MB",
        supportedFormats: "Supported formats: JPEG, PNG.",
        fileSize: "Max file size: 1 MB.",
        employeeFireMessage: "Are you sure you want to dismiss a person?",
        employeeRestoreMessage: "Are you sure you want to restore a person?",
        employeesFireMessage: "Are you sure you want to dismiss selected persons?",
        employeesRestoreMessage: "Are you sure you want to restore selected person?",
        employeesFireDeviceError: "You cannot dismiss these employees while the equipment is assigned to him",
        employeesArchiveDeviceError: "You cannot archive these employees while the equipment is assigned to him",
        employeesFireUserError: "You cannot dismiss these employees because they are users of the system",
        employeesArchiveUserError: "You cannot archive these employees because they are users of the system",
        employeeFireDeviceError: "You cannot dismiss a person while he has a wearable device",
        employeeArchiveDeviceError: "You cannot archive a person while he has a wearable device",
        employeeFireUserError: "You cannot dismiss a person because he is a user of the system",
        employeeArchiveUserError: "You cannot archive a person because he is a user of the system",
        rowsSelected: "Selected rows count",
        unlinkDevice: "Are you sure you want to unlink the device?",
    },
    positions: {
        title: "Positions",
        deleteDialog: {
            ok: "Archive",
            close: "Cancel",
            singleTitle: "Are you sure you want to archive a position?",
            multipleTitle: "Are you sure you want to archive selected positions?",
        },
        restoreDialog: {
            ok: "Restore",
            close: "Cancel",
            singleTitle: "Are you sure you want to restore a position?",
            multipleTitle: "Are you sure you want to restore selected positions?",
        },
        messageDialog: {
            ok: "Understandably",
            positionCannotBeArchived: " You cannot archive a position referenced by deployments",
        },
        table: {
            columns: {
                position: "Position",
                sync: "Sync.",
                synchronization: "Synchronization",
            },
            selection: "Selected",
            resetSelectionButton: "Reset selection",
            deleteButton: "Delete",
            filter: {
                name: "Name",
                placeholder: "Search",
                hintTop: "by position name",
                hintBottom: null,
                positionTable: "Positions",
                statuses: {
                    label: "Status",
                    values: {
                        active: "Active",
                        archived: "Archived",
                    },
                },
                buttons: {
                    clear: "Clear filter",
                },
            },
            createReportButton: "Create report",
            newButton: "Add position",
        },
        menu: {
            editButton: "Edit",
            archiveButton: "Archive",
            restoreButton: "Restore",
        },
        form: {
            newPositionDialogTitle: "New position",
            editPositionDialogTitle: "Position",
            submit: "Save",
            add: "Add",
            cancel: "Cancel",
            fields: {
                name: "Name",
                synchronization: "Synchronization",
            },
        },
        operations: {
            archive: "Archive",
            restore: "Restore",
        },
    },
    trainingTypes: {
        title: "Training types",
        deleteDialog: {
            ok: "Archive",
            close: "Cancel",
            singleTitle: "Are you sure you want to archive a training type?",
            multipleTitle: "Are you sure you want to archive selected training types?",
            multipleWithLinkedTitle: "Are you sure you want to archive selected training types? The types of training referenced by certificates will not be archived.",
        },
        restoreDialog: {
            ok: "Restore",
            close: "Cancel",
            singleTitle: "Are you sure you want to restore a training type?",
            multipleTitle: "Are you sure you want to restore selected training types?",
        },
        messageDialog: {
            ok: "Understandably",
            typeCannotBeArchived: "You cannot archive a training type referenced by certificates",
            typesCannotBeArchived: "You cannot archive training types referenced by certificates",
        },
        table: {
            columns: {
                name: "Name",
                code: "Code",
                description: "Description",
            },
            selection: "Selected",
            resetSelectionButton: "Reset selection",
            deleteButton: "Delete",
            filter: {
                name: "Name",
                placeholder: "Search",
                hintTop: "by training type's name",
                hintBottom: null,
                nameTable: "Training types",
                statusFilterLabel: "Status",
                status: {
                    active: "Active",
                    archived: "Archived",
                },
                buttons: {
                    clear: "Clear filter",
                },
            },
            createReportButton: "Create report",
            newButton: "Add training type",
        },
        menu: {
            editButton: "Edit",
            archiveButton: "Archive",
            restoreButton: "Restore",
        },
        form: {
            newTrainingTypeDialogTitle: "New training type",
            editTrainingTypeDialogTitle: "Training type",
            submit: "Save",
            add: "Add",
            cancel: "Cancel",
            fields: {
                name: "Name",
            },
        },
        operations: {
            archive: "Archive",
            restore: "Restore",
        },
    },
    certificates: {
        title: "Certificates",
        deleteDialog: {
            ok: "Archive",
            close: "Cancel",
            singleTitle: "Are you sure you want to archive a certificate?",
            multipleTitle: "Are you sure you want to archive selected certificates?",
        },
        restoreDialog: {
            ok: "Restore",
            close: "Cancel",
            singleTitle: "Are you sure you want to restore a certificate?",
            multipleTitle: "Are you sure you want to restore selected certificates?",
        },
        table: {
            columns: {
                number: "Number",
                code: "Training code",
                type: "Training type",
                person: "Person",
                issueDate: "Date of issue",
                expirationDate: "Date of expiration",
                learningCenter: "Learning Center",
            },
            selection: "Selected",
            resetSelectionButton: "Reset selection",
            deleteButton: "Delete",
            filter: {
                number: "Number",
                employee: "Person",
                trainingType: "Training type",
                trainingCode: "Training code",
                placeholder: "Search",
                hintTop: "by persons, training types, training codes, certificate's number",
                hintBottom: null,
                nameTable: "Certificates",
                statusFilterLabel: "Status",
                status: {
                    active: "Active",
                    archived: "Archived",
                },
                buttons: {
                    clear: "Clear filter",
                },
            },
            createReportButton: "Create report",
            newButton: "Add certificate",
        },
        menu: {
            editButton: "Edit",
            archiveButton: "Archive",
            restoreButton: "Restore",
        },
        form: {
            newTrainingTypeDialogTitle: "New certificate",
            editTrainingTypeDialogTitle: "Certificate",
            submit: "Save",
            add: "Add",
            cancel: "Cancel",
            fields: {
                selectEmployees: "Select some employees",
                selectTrainingType: "Select a training type",
                selectPerson: "Select a person",
                employees: "Employees",
            },
        },
        operations: {
            archive: "Archive",
            restore: "Restore",
        },
    },
    vehicles: {
        title: "Vehicles",
        menu: {
            editButton: "Edit",
            archiveButton: "Archive",
            restoreButton: "Restore",
        },
        deleteDialog: {
            ok: "Archive",
            close: "Cancel",
            singleItemTitle: "Are you sure you want to archive a vehicle?",
            multipleItemTitle: "Are you sure you want to archive selected vehicle?",
        },
        restoreDialog: {
            ok: "Restore",
            close: "Cancel",
            singleItemTitle: "Are you sure you want to restore a vehicle?",
            multipleItemTitle: "Are you sure you want to restore selected vehicle?",
        },
        messageDialog: {
            ok: "Understandably",
            vehiclesArchiveDeviceError: "You cannot archive these vehicles while the equipment is assigned to him",
            vehicleArchiveDeviceError: "You cannot archive a vehicle while the equipment is assigned to it",
        },
        table: {
            columns: {
                name: "Name",
                organizationId: "Organization",
                departmentId: "Department",
                registrationPlate: "Inventory number",
                inventoryNumber: "Inventory number",
                wearableDevices: "Wearable devices",
                syncEnabled: "Sync.",
                vehicleType: "Vehicle type",
            },
            selection: "Selected",
            resetSelectionButton: "Reset selection",
            deleteButton: "Archive",
            restoreButton: "Restore",
            filter: {
                placeholder: "Search",
                hintTop: "by vehicles, organizations,",
                hintBottom: "departments, types of vehicles",
                name: "Name",
                organization: "Organization",
                department: "Department",
                registrationPlate: "Inventory number",
                inventoryNumber: "Inventory number",
                vehicleType: "Vehicle type",
                statuses: {
                    label: "Status",
                    values: {
                        active: "Active",
                        archived: "Archived",
                    },
                },
                buttons: {
                    clear: "Clear filter",
                },
            },
            newVehicleButton: "Add device",
        },
        form: {
            newVehicleDialogTitle: "New vehicle",
            save: "Save",
            close: "Close",
            create: "Add",
            fields: {
                name: "Name",
                organizationId: "Organization",
                departmentId: "Department",
                registrationPlate: "Inventory number",
                inventoryNumber: "Inventory number",
                wearableDevices: "Wearable devices",
                syncEnabled: "Synchronized",
                vehicleType: "Vehicle type",
            },
        },
    },
    cards: {
        backToControl: "Back to catalog",
        status: "Status",
        deviceCard: {
            deviceCard: "Device card",
            telemetry: "Telemetry",
            history: "Device history",
            tagLocation: "Distance to beacons",
            trackable: "Person / vehicles",
        },
        employeeCard: {
            nearbyEmployees: "Nearby employees",
        },
        employee: {
            title: "Employee card",
        },
        messages: {
            successCopied: "Link Copied",
            copiedWithError: "Error in link copping",
        },
        copyUrl: "Copy Link",
    },
};
export default EN_CONTROL_RESOURCES;
