import WorkerMessage from "../WorkerMessage";

export type Value = {
    id: string;
};

class GetTimezoneWorkerMessage extends WorkerMessage<"GET_TIMEZONE_BY_ID", Value> {
    constructor(id: string, callerId: string) {
        super("GET_TIMEZONE_BY_ID", callerId, {
            id,
        });
    }
}

export default GetTimezoneWorkerMessage;
