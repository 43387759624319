export default class Camera {
    constructor(data) {
        this.id = data.id;
        this.ts = data.ts;
        this.name = data.name;
        this.position = data.position;
        this.code = data.code;
        this.rtspUrl = data.rtspUrl;
        this.lowResolutionRtspUrl = data.lowResolutionRtspUrl;
        this.zoneId = data.zoneId;
        this.model = data.model;
        this.viewAngleHorizontal = data.viewAngleHorizontal;
        this.viewAngleVertical = data.viewAngleVertical;
        this.zoom = data.zoom;
        this.onvifApiUrl = data.onvifApiUrl;
        this.resolutionId = data.resolutionId;
        this.codecId = data.codecId;
        this.fps = data.fps;
        this.heightAboveFloor = data.heightAboveFloor;
        this.installDirection = data.installDirection;
        this.currentDirection = data.currentDirection;
        this.faceRecognition = data.faceRecognition;
        this.status = data.status;
    }
}
