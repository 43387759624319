export default class VehicleLink {
    constructor(args) {
        this.id = args.id;
        this.name = args.name;
        this.type = args.type;
        this.organizationId = args.organizationId;
        this.organizationName = args.organizationName;
        this.departmentId = args.departmentId;
        this.departmentName = args.departmentName;
    }
}
