import { isUserCatalogEditingDisabled } from "@dwclient/config";
const RU_CONTROL_RESOURCES = {
    columnValueHolder: "Искать в таблице",
    searchPlaceholder: "Поиск",
    users: {
        title: "Пользователи",
        unbindDialog: {
            title: "Вы уверены, что хотите отвязать сотрудника?",
            close: "Отменить",
            ok: "Отвязать",
        },
        bindDialog: {
            title: "Связать с сотрудником",
            ok: "Связать",
        },
        restoreDialog: {
            singleOperationTitle: "Вы уверены, что хотите восстановить пользователя?",
            multipleOperationTitle: "Вы уверены, что хотите восстановить выбранных пользователей?",
            ok: "Восстановить",
            close: "Отменить",
        },
        archiveDialog: {
            singleOperationTitle: "Вы уверены, что хотите архивировать пользователя?",
            multipleOperationTitle: "Вы уверены, что хотите архивировать выбранных пользователей?",
            ok: "Архивировать",
            close: "Отменить",
        },
        form: {
            userBusy: "Выбранный сотрудник связан с другим пользователем.",
            fields: {
                roles: "Назначенные роли",
                login: "Логин",
                password: "Пароль",
                person: "Связанный сотрудник",
                source: "Способ аутентификации",
            },
            submitNew: "Добавить",
            submitOld: "Сохранить",
            titleNew: "Новый пользователь",
            titleOld: "Пользователь",
        },
        filter: {
            hintTop: `по логинам, ${isUserCatalogEditingDisabled ? "" : "ролям, "}сотрудникам, организациям,`,
            hintBottom: "подразделениям, должностям",
            roleId: "Роль",
            person: "Сотрудник",
            login: "Логин",
            statuses: {
                label: "Статус",
                values: {
                    active: "Активные",
                    archived: "Архивные",
                },
            },
        },
        table: {
            hideRoles: "Скрыть",
            showRoles: "Показать",
            setPerson: "Связать",
            unsetPerson: "Отвязать сотрудника",
            columns: {
                login: "Логин",
                person: "Сотрудник",
                roles: "Роли",
                organization: "Организация",
                department: "Подразделение",
                deployment: "Должность",
            },
            newUserButton: "Добавить пользователя",
        },
        operations: {
            archive: "Архивировать",
            restore: "Восстановить",
        },
    },
    filter: {
        emptySelect: "Пусто",
        search: {
            placeholder: {
                search: "Поиск",
                hintTop: "по сотрудникам, организациям,",
                hintBottom: "подразделениям и оборудованию",
            },
        },
    },
    organizations: {
        title: "Структура организаций",
        restoreOrganizationDialog: {
            singleOperationTitle: "Вы уверены, что хотите восстановить организацию?",
            multipleOperationTitle: "Вы уверены, что хотите восстановить выбранные организации?",
            ok: "Восстановить",
            close: "Отменить",
        },
        restoreSelectionDialog: {
            title: "Вы уверены, что хотите восстановить выбранные элементы справочника?",
            ok: "Восстановить",
            close: "Отменить",
        },
        restoreDepartmentDialog: {
            singleOperationTitle: "Вы уверены, что хотите восстановить подразделение?",
            multipleOperationTitle: "Вы уверены, что хотите восстановить выбранные подразделения?",
            ok: "Восстановить",
            close: "Отменить",
        },
        restoreDeploymentDialog: {
            singleOperationTitle: "Вы уверены, что хотите восстановить должностную позицию?",
            multipleOperationTitle: "Вы уверены, что хотите восстановить выбранные должностные позиции?",
            ok: "Восстановить",
            close: "Отменить",
        },
        archiveOrganizationDialog: {
            singleOperationTitle: "Вы уверены, что хотите архивировать организацию?",
            multipleOperationTitle: "Вы уверены, что хотите архивировать выбранные организации?",
            ok: "Архивировать",
            close: "Отменить",
        },
        archiveDepartmentDialog: {
            singleOperationTitle: "Вы уверены, что хотите архивировать подразделение?",
            multipleOperationTitle: "Вы уверены, что хотите архивировать выбранные подразделения?",
            ok: "Архивировать",
            close: "Отменить",
        },
        archiveDeploymentDialog: {
            singleOperationTitle: "Вы уверены, что хотите архивировать должностную позицию?",
            multipleOperationTitle: "Вы уверены, что хотите архивировать выбранные должностные позиции?",
            ok: "Архивировать",
            close: "Отменить",
        },
        messageDialog: {
            ok: "Понятно",
            organizationCannotBeArchived: "Вы не можете архивировать организацию, в которой есть сотрудники",
            departmentCannotBeArchived: "Вы не можете архивировать подразделение, в котором есть сотрудники",
            deploymentCannotBeArchived: "Вы не можете архивировать должностную позицию, на которую назначены сотрудники",
        },
        operations: {
            filter: "Фильтры",
            import: "Импорт",
            create: "Добавить организацию",
            search: "Поиск",
            selectOrganization: "Организация",
            selectDepartment: "Подразделение",
            hide: "Скрыть",
            show: "Показать",
            showOrganizationDetails: "Детали",
            showDepartmentDetails: "Детали",
            showDeploymentDetails: "Детали",
            edit: "Редактировать",
            delete: "Архивировать",
            cancel: "Отменить",
            clearSelection: "Сбросить выделение",
            deleteSelected: "Удалить выбранные",
            save: "Сохранить",
            add: "Добавить",
            addDeployment: "Создать должностную позицию",
            restore: "Восстановить",
            addPerson: "Назначить сотрудника",
            gotoEmployeesList: "Перейти к списку сотрудников",
        },
        filter: {
            departmentName: "Подразделение",
            deploymentId: "Должность",
            organizationName: "Организация",
            hintTop: "по организациям, подразделениям",
            hintBottom: "и должностям",
            status: {
                all: "Все",
                active: "Активные",
                archived: "Архивные",
            },
            buttons: {
                clear: "Очистить фильтр",
            },
        },
        fields: {
            organizationFull: "Структура организаций (кол-во подразделений)",
            department: "Подразделение",
            positions: "Должности",
            employees: "Сотрудники",
            position: "Должностные позиции",
            positionName: "Наименование",
            synchronization: "Синх.",
            email: "E-mail",
            phone: "Телефон",
            color: "Цвет",
            employee: "Сотрудник",
            organization: "Наименование",
            address: "Адрес",
            web: "Сайт",
            peculiarities: "Особенности",
            synchronizationFull: "Синхронизация",
        },
        bindDialog: {
            title: "Назначить на должность",
            ok: "Назначить",
            person: "Сотрудник",
        },
        organization: "Организация",
        newOrganization: "Новая организация",
        department: "Подразделение",
        newDepartment: "Новое подразделение",
        deployment: "Должностная позиция",
        newDeployment: "Новая должностная позиция",
        organizationDeleteMessage: "Вы уверены что хотите удалить организацию?",
        deploymentDeleteMessage: "Вы уверены что хотите удалить должностную позицию?",
        departmentDeleteMessage: "Вы уверены что хотите удалить подразделение?",
        addDepartmentsMessage: "Добавить подразделения и сотрудников можно после создания организации",
        rowsSelected: "Выбрано строк",
    },
    devices: {
        title: "Носимые устройства",
        menu: {
            editButton: "Редактировать",
            detailsButton: "Детали",
            archiveButton: "Архивировать",
            restoreButton: "Восстановить",
            historyButton: "История устройства",
        },
        issueDialog: {
            title: "Выдать",
            ok: "Выдать",
            forceOk: "Все равно выдать",
            trackable: "Выберите сотрудника или технику",
            personWithoutDeployment: "Сотрудник не назначен на Должностную позицию",
            operationDateTime: "Дата и время",
        },
        releaseDialog: {
            forceOk: "Все равно вернуть",
            ok: "Вернуть",
            cancel: "Закрыть",
            title: "Возврат носимого устройства",
            status: "Статус",
            description: "Вы уверены что хотите открепить устройство? Также вы можете указать причину.",
            operationDateTime: "Дата и время",
            validation: {
                deviceIsPermanent: "Устройство постоянного ношения",
            },
        },
        restoreDialog: {
            singleItemTitle: "Вы уверены, что хотите восстановить носимое устройство?",
            multipleItemTitle: "Вы уверены, что хотите восстановить носимые устройства?",
            close: "Отменить",
            ok: "Восстановить",
        },
        deleteDialog: {
            singleItemTitle: "Вы уверены, что хотите архивировать носимое устройство?",
            multipleItemTitle: "Вы уверены, что хотите архивировать носимые устройства?",
            close: "Отменить",
            ok: "Архивировать",
        },
        tagSelectorDialog: {
            findPlaceholder: "Найти по наименованию или UID",
        },
        messageDialog: {
            archiveErrorMessage: 'Вы не можете архивировать носимое устройство в статусе "Выдано"',
            ok: "Понятно",
        },
        issueTypeFilter: {
            title: "Показывать устройства постоянного ношения",
        },
        tagHistoryDialog: {
            title: "История устройства",
            columns: {
                employee: "Сотрудник",
            },
        },
        fastDeviceIssuingDialog: {
            title: "Оперативная выдача",
            deviceNotFound: "Носимое устройство не зарегистрировано в системе",
            personNotFound: "Сотрудник не найден",
            personNotSelected: "Не выбран",
            deviceNotSelected: "Не выбрано",
            personField: "Сотрудник",
            devicesField: "Носимое устройство",
            cancel: "Закрыть",
            connectionStatus: "Соединение",
            issue: "Выдать",
            reset: "Отменить",
            submissionError: "Не удалось сохранить все устройства",
        },
        deviceScannerDialog: {
            hint: "Поднесите сканер к носимому устройству",
            cancel: "Отменить",
        },
        table: {
            columns: {
                status: "Статус",
                lastModifiedAt: "Дата изменения",
                lastModifiedBy: "Изменил",
                name: "Наименование",
                vendorId: "Device UID",
                trackable: "Закреплено за",
            },
            deviceScannerButton: "Использовать сканер",
            promptDistributionButton: "Оперативная выдача",
            deviceData: "Данные устройства",
            setPerson: "Выдать устройство",
            selection: "Выбрано записей",
            deleteButton: "Архивировать",
            restoreButton: "Восстановить",
            resetSelectionButton: "Сбросить выделение",
            filter: {
                status: "Статус",
                placeholder: "Поиск",
                hintTop: "по моделям, сотрудникам,",
                hintBottom: "наименованиям и оборудованию",
                tagTypeId: "Модель",
                trackable: "Сотрудник",
                name: "Наименование",
                vendorId: "Device UID",
                buttons: {
                    clear: "Очистить фильтр",
                },
            },
            newDeviceButton: "Добавить устройство",
            createReportButton: "Сформировать отчёт",
        },
        form: {
            newDeviceDialogTitle: "Новое устройство",
            save: "Сохранить",
            create: "Добавить",
            fields: {
                tagTypeId: "Модель",
                externalSystemId: "Внешняя система",
                name: "Наименование",
                vendorId: "Device UID",
                status: "Статус",
                tagProfileId: "Профиль СИЗ",
            },
        },
    },
    employees: {
        title: "Сотрудники",
        search: {
            placeholder: "Поиск",
            hintTop: "по сотрудникам, организациям",
            hintBottom: "подразделениям и должностям",
            name: "ФИО сотрудника",
            columnSearch: "Поиск в таблице",
            department: "Подразделение",
            deployment: "Должность",
            position: "Должность",
            organization: "Организация",
            buttons: {
                clear: "Очистить фильтр",
            },
        },
        filter: {
            statuses: {
                label: "Статус",
                values: {
                    appointed: "Назначенные",
                    unassigned: "Неназначенные",
                    archived: "Архивные",
                },
            },
        },
        editEmployeeDialog: {
            addEmployeeTitle: "Новый сотрудник",
            editEmployeeTitle: "Сотрудник",
            addButton: "Добавить",
            editButton: "Сохранить",
        },
        operations: {
            filter: "Фильтры",
            import: "Импорт",
            create: "Добавить сотрудника",
            add: "Добавить",
            forceAdd: "Все равно добавить",
            save: "Сохранить",
            ok: "Понятно",
            loadPhoto: "Прикрепить фотографию",
            delete: "Удалить",
            edit: "Редактировать",
            showDetails: "Детали",
            fire: "Уволить",
            restore: "Восстановить",
            cancel: "Отменить",
            clearSelection: "Сбросить выделение",
            fireSelected: "Уволить",
            restoreSelected: "Восстановить",
            unlink: "Отвязать",
        },
        fields: {
            firstName: "Имя",
            lastName: "Фамилия",
            middleName: "Отчество",
            organization: "Организация",
            department: "Подразделение",
            position: "Должностная позиция",
            devices: "Носимые устр-ва",
            addDevice: "Добавить носимые устройства",
            synchronization: "Синх.",
            email: "E-mail",
            phone: "Телефон",
            employee: "Сотрудник",
            model: "Модель",
            primaryChannel: "Приоритетный канал связи",
            externalId: "Табельный номер",
            gender: "Пол",
            birthDate: "Дата рождения",
            lastHealthCheck: "Дата прохождения медосмотра",
            schedule: "График работы",
            age: "лет",
        },
        fileSizeError: "Размер файла не должен превышать 1 МБ",
        supportedFormats: "Поддерживаемые форматы: JPEG, PNG.",
        fileSize: "Макс. размер: 1 МБ.",
        employeeFireMessage: "Вы уверены, что хотите уволить сотрудника?",
        employeeRestoreMessage: "Вы уверены, что хотите восстановить сотрудника?",
        employeesFireMessage: "Вы уверены, что хотите уволить сотрудников?",
        employeesRestoreMessage: "Вы уверены, что хотите восстановить сотрудников?",
        employeesFireDeviceError: "Вы не можете уволить этих сотрудников, пока за ним закреплено оборудование",
        employeesArchiveDeviceError: "Вы не можете архивировать этих сотрудников, пока за ним закреплено оборудование",
        employeesFireUserError: "Вы не можете уволить этих сотрудников,потому что они являются пользователями системы",
        employeesArchiveUserError: "Вы не можете архивировать этих сотрудников, потому что они являются пользователями системы",
        employeeFireDeviceError: "Вы не можете уволить сотрудника, пока за ним закреплено оборудование",
        employeeArchiveDeviceError: "Вы не можете архивировать сотрудника, пока за ним закреплено оборудование",
        employeeFireUserError: "Вы не можете уволить сотрудника, потому что он является пользователем системы",
        employeeArchiveUserError: "Вы не можете архивировать сотрудника, потому что он является пользователем системы",
        rowsSelected: "Выбрано строк",
        unlinkDevice: "Вы уверены что хотите отвязать устройство?",
    },
    positions: {
        title: "Должности",
        deleteDialog: {
            singleTitle: "Вы уверены, что хотите архивировать должность?",
            multipleTitle: "Вы уверены, что хотите архивировать должности?",
            close: "Отменить",
            ok: "Архивировать",
        },
        restoreDialog: {
            singleTitle: "Вы уверены, что хотите восстановить должность?",
            multipleTitle: "Вы уверены, что хотите восстановить должности?",
            close: "Отменить",
            ok: "Восстановить",
        },
        messageDialog: {
            ok: "Понятно",
            positionCannotBeArchived: "Вы не можете архивировать должность, на которую ссылаются должностные позиции",
        },
        table: {
            columns: {
                position: "Должность",
                sync: "Синх.",
                synchronization: "Синхронизация",
            },
            selection: "Выбрано записей",
            deleteButton: "Удалить",
            resetSelectionButton: "Сбросить выделение",
            filter: {
                placeholder: "Поиск",
                hintTop: "по наименованию должности",
                hintBottom: null,
                name: "Название",
                positionTable: "Должности",
                statuses: {
                    label: "Статус",
                    values: {
                        active: "Активные",
                        archived: "Архивные",
                    },
                },
                buttons: {
                    clear: "Очистить фильтр",
                },
            },
            newButton: "Добавить должность",
            createReportButton: "Сформировать отчёт",
        },
        menu: {
            editButton: "Редактировать",
            archiveButton: "Архивировать",
            restoreButton: "Восстановить",
        },
        form: {
            newPositionDialogTitle: "Новая должность",
            editPositionDialogTitle: "Должность",
            add: "Добавить",
            submit: "Сохранить",
            cancel: "Отменить",
            fields: {
                name: "Название",
                synchronization: "Синхронизация",
            },
        },
        operations: {
            archive: "Архивировать",
            restore: "Восстановить",
        },
    },
    trainingTypes: {
        title: "Типы обучений",
        deleteDialog: {
            singleTitle: "Вы уверены, что хотите архивировать тип обучения?",
            multipleTitle: "Вы уверены, что хотите архивировать типы обучения?",
            multipleWithLinkedTitle: "Вы уверены, что хотите архивировать выбранные типы обучения? Типы обучения, на которые ссылаются сертификаты не будут заархивированы",
            close: "Отменить",
            ok: "Архивировать",
        },
        restoreDialog: {
            singleTitle: "Вы уверены, что хотите восстановить тип обучения?",
            multipleTitle: "Вы уверены, что хотите восстановить типы обучения?",
            close: "Отменить",
            ok: "Восстановить",
        },
        messageDialog: {
            ok: "Понятно",
            typeCannotBeArchived: "Вы не можете архивировать тип обучения, на который ссылаются сертификаты",
            typesCannotBeArchived: "Вы не можете архивировать типы обучения, на которые ссылаются сертификаты",
        },
        table: {
            columns: {
                name: "Наименование",
                description: "Описание",
                code: "Код",
            },
            selection: "Выбрано записей",
            deleteButton: "Удалить",
            resetSelectionButton: "Сбросить выделение",
            filter: {
                placeholder: "Поиск",
                hintTop: "по наименованию типа обучения",
                hintBottom: null,
                name: "Наименование",
                nameTable: "Наименование",
                statusFilterLabel: "Статус",
                status: {
                    archived: "Архивные",
                    active: "Активные",
                },
                buttons: {
                    clear: "Очистить фильтр",
                },
            },
            newButton: "Добавить тип обучения",
            createReportButton: "Сформировать отчёт",
        },
        menu: {
            editButton: "Редактировать",
            archiveButton: "Архивировать",
            restoreButton: "Восстановить",
        },
        form: {
            newTrainingTypeDialogTitle: "Новый тип обучения",
            editTrainingTypeDialogTitle: "Тип обучения",
            add: "Добавить",
            submit: "Сохранить",
            cancel: "Отменить",
            fields: {
                name: "Название",
            },
        },
        operations: {
            archive: "Архивировать",
            restore: "Восстановить",
        },
    },
    certificates: {
        title: "Сертификаты",
        deleteDialog: {
            singleTitle: "Вы уверены, что хотите архивировать сертификат?",
            multipleTitle: "Вы уверены, что хотите архивировать выбранные сертификаты?",
            close: "Отменить",
            ok: "Архивировать",
        },
        restoreDialog: {
            singleTitle: "Вы уверены, что хотите восстановить сертификат?",
            multipleTitle: "Вы уверены, что хотите восстановить выбранные сертификаты?",
            close: "Отменить",
            ok: "Восстановить",
        },
        table: {
            columns: {
                number: "Номер",
                code: "Код обучения",
                type: "Тип обучения",
                person: "Сотрудник",
                issueDate: "Дата выдачи",
                expirationDate: "Дата истечения",
                learningCenter: "Обучающий центр",
            },
            selection: "Выбрано записей",
            deleteButton: "Удалить",
            resetSelectionButton: "Сбросить выделение",
            filter: {
                placeholder: "Поиск",
                hintTop: "по сотрудникам, типу обучения, коду обучения, номеру сертификата",
                hintBottom: null,
                number: "Номер",
                employee: "Сотрудник",
                trainingType: "Тип обучения",
                trainingCode: "Код обучения",
                nameTable: "Наименование",
                statusFilterLabel: "Статус",
                status: {
                    archived: "Архивные",
                    active: "Активные",
                },
                buttons: {
                    clear: "Очистить фильтр",
                },
            },
            newButton: "Добавить сертификат",
            createReportButton: "Сформировать отчёт",
        },
        menu: {
            editButton: "Редактировать",
            archiveButton: "Архивировать",
            restoreButton: "Восстановить",
        },
        form: {
            newTrainingTypeDialogTitle: "Новый cертификат",
            editTrainingTypeDialogTitle: "Сертификат",
            add: "Добавить",
            submit: "Сохранить",
            cancel: "Отменить",
            fields: {
                selectEmployees: "Выбрать несколько сотрудников",
                selectTrainingType: "Выберите тип обучения",
                selectPerson: "Выберите сотрудника",
                employees: "Сотрудники",
            },
        },
        operations: {
            archive: "Архивировать",
            restore: "Восстановить",
        },
    },
    vehicles: {
        title: "Техника",
        menu: {
            editButton: "Редактировать",
            archiveButton: "Архивировать",
            restoreButton: "Восстановить",
        },
        restoreDialog: {
            singleItemTitle: "Вы уверены, что хотите восстановить технику?",
            multipleItemTitle: "Вы уверены, что хотите восстановить технику?",
            close: "Отменить",
            ok: "Восстановить",
        },
        deleteDialog: {
            singleItemTitle: "Вы уверены, что хотите архивировать технику?",
            multipleItemTitle: "Вы уверены, что хотите архивировать технику?",
            close: "Отменить",
            ok: "Архивировать",
        },
        messageDialog: {
            ok: "Понятно",
            vehiclesArchiveDeviceError: "Вы не можете архивировать технику, пока за ней закреплено оборудование",
            vehicleArchiveDeviceError: "Вы не можете архивировать технику, пока за ней закреплено оборудование",
        },
        table: {
            columns: {
                name: "Наименование",
                organizationId: "Организация",
                departmentId: "Подразделение",
                registrationPlate: "Государственный регистрационный знак",
                inventoryNumber: "Инвентарный номер",
                wearableDevices: "Носимые устройства",
                syncEnabled: "Синх.",
                vehicleType: "Тип техники",
            },
            selection: "Выбрано записей",
            resetSelectionButton: "Сбросить выделение",
            deleteButton: "Архивировать",
            restoreButton: "Восстановить",
            filter: {
                placeholder: "Поиск",
                hintTop: "по транспортным средствам, организациям,",
                hintBottom: "подразделениям, типу техники",
                name: "Наименование",
                organization: "Организация",
                department: "Подразделение",
                registrationPlate: "Государственный регистрационный знак",
                inventoryNumber: "Инвентарный номер",
                vehicleType: "Тип техники",
                statuses: {
                    label: "Статус",
                    values: {
                        active: "Активные",
                        archived: "Архивные",
                    },
                },
                buttons: {
                    clear: "Очистить фильтр",
                },
            },
            newVehicleButton: "Добавить технику",
        },
        form: {
            newVehicleDialogTitle: "Новая техника",
            save: "Сохранить",
            create: "Добавить",
            close: "Закрыть",
            fields: {
                name: "Наименование",
                organizationId: "Организация",
                departmentId: "Подразделение",
                registrationPlate: "Государственный регистрационный знак",
                inventoryNumber: "Инвентарный номер",
                wearableDevices: "Транспортная метка",
                syncEnabled: "Синхронизируется",
                vehicleType: "Тип техники",
            },
        },
    },
    cards: {
        backToControl: "Вернуться к справочнику",
        status: "Статус",
        deviceCard: {
            deviceCard: "Карточка устройства",
            telemetry: "Телеметрия",
            history: "История устройства",
            tagLocation: "Расстояние до маяков",
            trackable: "Сотрудник / техника",
        },
        employeeCard: {
            nearbyEmployees: "Сотрудники рядом",
        },
        employee: {
            title: "Карточка сотрудника",
        },
        messages: {
            successCopied: "Ссылка скопирована",
            copiedWithError: "Не удалось скопировать ссылку",
        },
        copyUrl: "Скопировать ссылку",
    },
};
export default RU_CONTROL_RESOURCES;
