export function overrideObjects<N, O extends N>(originalObject: O, newObject: N) {
    const resultObject = {} as O;
    for (const key of [...Object.keys(originalObject), ...Object.keys(newObject)]) {
        const props = key as keyof O;
        if (
            Object.prototype.hasOwnProperty.call(originalObject, props) &&
            Object.prototype.hasOwnProperty.call(newObject, props) &&
            typeof originalObject[props] === "object"
        ) {
            resultObject[props] = overrideObjects(originalObject[props], (newObject as O)[props]);
            continue;
        }
        if (Object.prototype.hasOwnProperty.call(originalObject, props)) {
            resultObject[props] = originalObject[props];
        }
        if (Object.prototype.hasOwnProperty.call(newObject, props)) {
            resultObject[props] = (newObject as O)[props];
        }
    }
    return resultObject;
}
