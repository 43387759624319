const enEditorResources = {
    routeMaps: {
        title: "Route maps",
        create: "Create route map",
        newTitle: "Route map settings",
        deleteDialog: {
            noButton: "Cancel",
            deleteButton: "Delete",
            title: "Are you sure you want to delete the route map?",
        },
        messages: {
            emptyMapName: "New map",
            emptyShiftCycle: "Shift schedule is empty",
            emptyPointName: "Equipment was not selected",
            emptyEquipmentError: "Equipment must be configured",
            emptyDeploymentsError: "Deployment must be selected",
            emptyPointsError: "Points list can't be empty",
            deletePointDialog: {
                close: "Cancel",
                ok: "Delete",
                title: "Are you sure you want to delete the route point?",
            },
            selectRoutePointDialog: {
                title: "Select route point",
                routeMap: "Route map",
                routePoint: "Route point",
                useAllPoints: "Select all route points",
                cancel: "Cancel",
                select: "Select",
            },
        },
        forms: {
            mapForm: {
                active: "Execute",
                name: "Name",
                calendar: "Execute by days",
                interval: "Execute with the interval",
                shiftCycle: "Shift schedule",
                deployments: "Deployments",
            },
            pointForm: {
                delete: "Delete route point",
                equipments: {
                    name: "Name",
                    inspectorsNumber: "Inspectors count",
                },
                inspectionFrequency: "Inspection Frequency",
                periodicity: "Period",
                inspectionsNumber: "Times",
                calendar: "Calendar",
                shifts: "Work shifts",
                tabs: {
                    parameters: "Parameters",
                    equipments: "Equipment",
                },
                active: "Execute",
                notActive: "Not execute",
                period: "Inspection time",
                startTime: "From",
                endTime: "To",
            },
            points: {
                add: "Add route point",
                copy: "Copy route point",
                delete: "Delete",
            },
            deployments: {
                delete: "Remove",
                add: "Add deployment",
            },
        },
        calendarPickAll: "all",
    },
};
export default enEditorResources;
