export default class DWFile {
    constructor(data, url) {
        this.name = data.name;
        if (!data.contentType) {
            console.warn(`ContentType for DWFile is not set for file ${this.name}!`);
        }
        this.mimeType = data.contentType;
        this.title = data.name;
        this.created =
            (data.createdAt && new Date(data.createdAt)) || (data.lastModifiedAt && new Date(data.lastModifiedAt));
        this.lastModified = data.lastModifiedAt && new Date(data.lastModifiedAt);
        this.path = data.path;
        const execArray = /^\/[\w\d-_]+((\/[\w\d-_]+)*)/i.exec(this.path);
        this.dir = execArray && execArray[1] && execArray[1] !== "" && execArray[1].replace(/^\//, "");
        this.size = data.size | 0;
        this.url = url;
    }
}
