class PersonLink {
    constructor(args) {
        this.id = args.id;
        this.name = args.name;
        this.firstName = args.firstName;
        this.lastName = args.lastName;
        this.middleName = args.middleName;
        this.photoUrl = args.photoUrl;
        this.type = args.type;
        this.organizationId = args.organizationId;
        this.organizationName = args.organizationName;
        this.deploymentName = args.deploymentName;
        this.deploymentId = args.deploymentId;
        this.email = args.email;
        this.phone = args.phone;
        this.hasTelegram = args.hasTelegram;
        this.externalId = args.externalId;
        this.departments = args.departments;
    }
    static getFullName(personLink) {
        return (personLink &&
            PersonLink.convertToString(personLink.lastName) +
                " " +
                PersonLink.convertToString(personLink.firstName) +
                " " +
                PersonLink.convertToString(personLink.middleName));
    }
    static getShortName(personLink) {
        if (!personLink) {
            return "";
        }
        const lastName = PersonLink.convertToString(personLink.lastName);
        const firstName = PersonLink.convertToString(personLink.firstName);
        const middleName = PersonLink.convertToString(personLink.middleName);
        return (lastName +
            (firstName ? " " + firstName.charAt(0).toUpperCase() + "." : "") +
            (middleName ? " " + middleName.charAt(0).toUpperCase() + "." : ""));
    }
    static convertToString(value) {
        return value || "";
    }
}
export default PersonLink;
