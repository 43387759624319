class BaseViewPoint {
    constructor({ position, xyz }) {
        if (!xyz || xyz.x === undefined || xyz.y === undefined || xyz.z === undefined) {
            throw new Error("XYZ is not defined");
        }
        this.orientation = {
            x: xyz.x,
            y: xyz.y,
            z: xyz.z,
        };
        if (!position ||
            position.longitude === undefined ||
            position.latitude === undefined ||
            position.altitude === undefined) {
            throw new Error("Position is not defined");
        }
        this.destination = {
            latitude: position.latitude,
            longitude: position.longitude,
            altitude: position.altitude,
        };
    }
}
export default BaseViewPoint;
