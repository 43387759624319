/**
 * Enum с правами для простоты разработки.
 * <h2>Не для списков!</h4>
 */
var UserAuthorityEnum;
(function (UserAuthorityEnum) {
    /**
     * Отображение справочника пользователей
     */
    UserAuthorityEnum["S_CAT_USR_MENU"] = "S-CAT-USR-MENU";
    /**
     * Доступ к информации о пользователях
     */
    UserAuthorityEnum["S_CAT_USR_V"] = "S-CAT-USR-V";
    /**
     * Доступ к редактированию пользователей
     */
    UserAuthorityEnum["S_CAT_USR_E"] = "S-CAT-USR-E";
    /**
     * Доступ к информации о группах отслеживаемых объектов
     */
    UserAuthorityEnum["S_CAT_TRACK_GROUP_V"] = "S-CAT-TRACK-GROUP-V";
    /**
     * Доступ к редактированию информации о группах отслеживаемых объектов
     */
    UserAuthorityEnum["S_CAT_TRACK_GROUP_E"] = "S-CAT-TRACK-GROUP-E";
    /**
     * Отображение справочника сотрудников
     */
    UserAuthorityEnum["S_CAT_PERS_MENU"] = "S-CAT-PERS-MENU";
    /**
     * Доступ к информации о сотрудниках
     */
    UserAuthorityEnum["S_CAT_PERS_V"] = "S-CAT-PERS-V";
    /**
     * Доступ к редактированию информации о сотрудниках
     */
    UserAuthorityEnum["S_CAT_PERS_E"] = "S-CAT-PERS-E";
    /**
     * Отображение справочника типов техники
     */
    UserAuthorityEnum["S_CAT_VEHT_MENU"] = "S-CAT-VEHT-MENU";
    /**
     * Доступ к информации о типах техники
     */
    UserAuthorityEnum["S_CAT_VEHT_V"] = "S-CAT-VEHT-V";
    /**
     * Доступ к редактированию информации о типах техники
     */
    UserAuthorityEnum["S_CAT_VEHT_E"] = "S-CAT-VEHT-E";
    /**
     * Отображение справочника техники
     */
    UserAuthorityEnum["S_CAT_VEH_MENU"] = "S-CAT-VEH-MENU";
    /**
     * Доступ к информации о технике
     */
    UserAuthorityEnum["S_CAT_VEH_V"] = "S-CAT-VEH-V";
    /**
     * Доступ к редактированию информации о технике
     */
    UserAuthorityEnum["S_CAT_VEH_E"] = "S-CAT-VEH-E";
    /**
     * Доступ к информации об отслеживаемых объектах
     */
    UserAuthorityEnum["S_CAT_TRACK_V"] = "S-CAT-TRACK-V";
    /**
     * Доступ к редактированию информации об отслеживаемых объектах
     */
    UserAuthorityEnum["S_CAT_TRACK_E"] = "S-CAT-TRACK-E";
    /**
     * Доступ к информации о внешних системах
     */
    UserAuthorityEnum["EXT_SYS_V"] = "EXT-SYS-V";
    /**
     * Доступ к редактированию информации о внешних системах
     */
    UserAuthorityEnum["EXT_SYS_E"] = "EXT-SYS-E";
    /**
     * Отображение редактора локаций
     */
    UserAuthorityEnum["LOCATION_MENU"] = "LOCATION-MENU";
    /**
     * Доступ к информации о локациях
     */
    UserAuthorityEnum["LOCATION_V"] = "LOCATION-V";
    /**
     * Доступ к редактированию информации о локациях
     */
    UserAuthorityEnum["LOCATION_E"] = "LOCATION-E";
    /**
     * Доступ к информации о локаторах
     */
    UserAuthorityEnum["LOCATOR_V"] = "LOCATOR-V";
    /**
     * Доступ к редактированию информации о локаторах
     */
    UserAuthorityEnum["LOCATOR_E"] = "LOCATOR-E";
    /**
     * Отображение справочника типов СИЗ
     */
    UserAuthorityEnum["PPE_MENU"] = "PPE-MENU";
    /**
     * Доступ к информации о типах СИЗ
     */
    UserAuthorityEnum["PPE_V"] = "PPE-V";
    /**
     * Доступ к редактированию информации о типах СИЗ
     */
    UserAuthorityEnum["PPE_E"] = "PPE-E";
    /**
     * Отображение редактора датчиков
     */
    UserAuthorityEnum["SENSOR_MENU"] = "SENSOR-MENU";
    /**
     * Доступ к информации о датчиках
     */
    UserAuthorityEnum["SENSOR_V"] = "SENSOR-V";
    /**
     * Доступ к редактированию информации о датчиках
     */
    UserAuthorityEnum["SENSOR_E"] = "SENSOR-E";
    /**
     * Отображение справочника профилей носимых устройств
     */
    UserAuthorityEnum["TAG_PROFILE_MENU"] = "TAG-PROFILE-MENU";
    /**
     * Доступ к информации о профилях носимых устройств
     */
    UserAuthorityEnum["TAG_PROFILE_V"] = "TAG-PROFILE-V";
    /**
     * Доступ к редактированию информации о профилях носимых устройств
     */
    UserAuthorityEnum["TAG_PROFILE_E"] = "TAG-PROFILE-E";
    /**
     * Отображение справочника носимых устройств
     */
    UserAuthorityEnum["S_CAT_WDEV_MENU"] = "S-CAT-WDEV-MENU";
    /**
     * Доступ к информации о носимых устройствах
     */
    UserAuthorityEnum["S_CAT_WDEV_V"] = "S-CAT-WDEV-V";
    /**
     * Доступ к редактированию информации о носимых устройствах
     */
    UserAuthorityEnum["S_CAT_WDEV_E"] = "S-CAT-WDEV-E";
    /**
     * Доступ к редактированию информации о носимых устройствах с указанием времени изменения статуса
     */
    UserAuthorityEnum["S_CAT_WDEV_DISTR_TIME_E"] = "S-CAT-WDEV-DISTR-TIME-E";
    /**
     * Доступ к информации о типах носимых устройств
     */
    UserAuthorityEnum["S_CAT_WDEV_TYPE_V"] = "S-CAT-WDEV-TYPE-V";
    /**
     * Доступ к редактированию информации о типах носимых устройств
     */
    UserAuthorityEnum["S_CAT_WDEV_TYPE_E"] = "S-CAT-WDEV-TYPE-E";
    /**
     * Доступ к информации связанной с сотрудниками
     */
    UserAuthorityEnum["S_CAT_WDEV_TRACK_V"] = "S-CAT-WDEV-TRACK-V";
    /**
     * Отображение справочника организаций
     */
    UserAuthorityEnum["S_CAT_ORGS_MENU"] = "S-CAT-ORGS-MENU";
    /**
     * Доступ к информации об организациях
     */
    UserAuthorityEnum["S_CAT_ORGS_V"] = "S-CAT-ORGS-V";
    /**
     * Доступ к редактированию информации об организациях
     */
    UserAuthorityEnum["S_CAT_ORGS_E"] = "S-CAT-ORGS-E";
    /**
     * Отображение справочника должностей
     */
    UserAuthorityEnum["S_CAT_POS_MENU"] = "S-CAT-POS-MENU";
    /**
     * Доступ к информации о должностях
     */
    UserAuthorityEnum["S_CAT_POS_V"] = "S-CAT-POS-V";
    /**
     * Доступ к редактированию информации о должностях
     */
    UserAuthorityEnum["S_CAT_POS_E"] = "S-CAT-POS-E";
    /**
     * Доступ к информации о категориях групп
     */
    UserAuthorityEnum["GROUP_CATEGORY_V"] = "GROUP-CATEGORY-V";
    /**
     * Доступ к редактированию информации о категориях групп
     */
    UserAuthorityEnum["GROUP_CATEGORY_E"] = "GROUP-CATEGORY-E";
    /**
     * Доступ к информации о группах
     */
    UserAuthorityEnum["GROUP_V"] = "GROUP-V";
    /**
     * Доступ к редактированию информации о группах
     */
    UserAuthorityEnum["GROUP_E"] = "GROUP-E";
    /**
     * Отправка события обновления сущности
     */
    UserAuthorityEnum["GROUP_SEND_UPDATE"] = "GROUP-SEND-UPDATE";
    /**
     * Доступ к данным о признаках бизнес-правил
     */
    UserAuthorityEnum["ZONES_BUS_RULE_V"] = "ZONES_BUS-RULE-V";
    /**
     * Доступ к редактированию данных о признаках бизнес-правил
     */
    UserAuthorityEnum["ZONES_BUS_RULE_E"] = "ZONES_BUS-RULE-E";
    /**
     * Доступ к данным о категориях геозоны
     */
    UserAuthorityEnum["ZONES_TIME_UTIL_V"] = "ZONES_TIME-UTIL-V";
    /**
     * Доступ к редактированию данных о категориях геозоны
     */
    UserAuthorityEnum["ZONES_TIME_UTIL_E"] = "ZONES_TIME-UTIL-E";
    /**
     * Отображение меню редактирования геозон
     */
    UserAuthorityEnum["ZONES_GFEN_MENU"] = "ZONES_GFEN-MENU";
    /**
     * Доступ к данным о геозонах
     */
    UserAuthorityEnum["ZONES_GFEN_V"] = "ZONES_GFEN-V";
    /**
     * Доступ к редактированию данных о геозонах
     */
    UserAuthorityEnum["ZONES_GFEN_E"] = "ZONES_GFEN-E";
    /**
     * Доступ к моделям объектов
     */
    UserAuthorityEnum["ZONES_MODEL_V"] = "ZONES_MODEL-V";
    /**
     * Доступ к редактору геозон
     */
    UserAuthorityEnum["ZONES_BUILDING_MENU"] = "ZONES_BUILDING-MENU";
    /**
     * Доступ к данным об объектах
     */
    UserAuthorityEnum["ZONES_BUILDING_V"] = "ZONES_BUILDING-V";
    /**
     * Доступ к редактированию данных об объектах
     */
    UserAuthorityEnum["ZONES_BUILDING_E"] = "ZONES_BUILDING-E";
    /**
     * Доступ к методам по вычислению позиции
     */
    UserAuthorityEnum["ZONES_POSITION"] = "ZONES_POSITION";
    /**
     * Доступ к данным о кодеках
     */
    UserAuthorityEnum["CAMERAS_CODEC_V"] = "CAMERAS_CODEC-V";
    /**
     * Доступ к данным о разрешениях
     */
    UserAuthorityEnum["CAMERAS_RESOLUTION_V"] = "CAMERAS_RESOLUTION-V";
    /**
     * Отображение меню с камерами
     */
    UserAuthorityEnum["CAMERAS_MENU"] = "CAMERAS_MENU";
    /**
     * Доступ к получению данных о камере
     */
    UserAuthorityEnum["CAMERAS_V"] = "CAMERAS_V";
    /**
     * Доступ к редактированию камер
     */
    UserAuthorityEnum["CAMERAS_E"] = "CAMERAS_E";
    /**
     * Доступ к данным о положении треков камеры
     */
    UserAuthorityEnum["CAMERAS_TRACK_V"] = "CAMERAS_TRACK-V";
    /**
     * Расчёт географических координат по координатам на кадре указанной камеры
     */
    UserAuthorityEnum["CAMERAS_CALC"] = "CAMERAS_CALC";
    /**
     * Просмотр истории события
     */
    UserAuthorityEnum["INCIDENTS_HISTORY_V"] = "INCIDENTS_HISTORY-V";
    /**
     * Редактирование сырых событий из истории
     */
    UserAuthorityEnum["INCIDENTS_HISTORY_RAW_E"] = "INCIDENTS_HISTORY-RAW-E";
    /**
     * Доступ к редактору типов событий
     */
    UserAuthorityEnum["INCIDENTS_TYPE_MENU"] = "INCIDENTS_TYPE-MENU";
    /**
     * Доступ к данным о типах событий
     */
    UserAuthorityEnum["INCIDENTS_TYPE_V"] = "INCIDENTS_TYPE-V";
    /**
     * Доступ к редактированию данных о событии
     */
    UserAuthorityEnum["INCIDENTS_TYPE_E"] = "INCIDENTS_TYPE-E";
    /**
     * Доступ к данным о тегах событий
     */
    UserAuthorityEnum["INCIDENTS_TAGS_V"] = "INCIDENTS_TAGS-V";
    /**
     * Доступ к редактированию данных о тегах событий
     */
    UserAuthorityEnum["INCIDENTS_TAGS_E"] = "INCIDENTS_TAGS-E";
    /**
     * Доступ к просмотру журнала событий
     */
    UserAuthorityEnum["INCIDENTS_MENU"] = "INCIDENTS_MENU";
    /**
     * Доступ к данным о событиях
     */
    UserAuthorityEnum["INCIDENTS_V"] = "INCIDENTS_V";
    /**
     * Доступ к редактированию данных о событиях
     */
    UserAuthorityEnum["INCIDENTS_E"] = "INCIDENTS_E";
    /**
     * Запрос информации о состояниях
     */
    UserAuthorityEnum["STATEMACHINE_INFO_V"] = "STATEMACHINE_INFO-V";
    /**
     * Исполнение перехода между состояниями
     */
    UserAuthorityEnum["STATEMACHINE_EXECUTE"] = "STATEMACHINE_EXECUTE";
    /**
     * Просмотр информации о каналах доставки
     */
    UserAuthorityEnum["NOTIFICATIONS_MESSAGE_CHANNEL_V"] = "NOTIFICATIONS_MESSAGE-CHANNEL-V";
    /**
     * Просмотр информации о каналах доставки отслеживаемого объекта
     */
    UserAuthorityEnum["NOTIFICATIONS_TRACK_CHANNEL_V"] = "NOTIFICATIONS_TRACK-CHANNEL-V";
    /**
     * Редактирование информации о каналах доставки отслеживаемого объекта
     */
    UserAuthorityEnum["NOTIFICATIONS_TRACK_CHANNEL_E"] = "NOTIFICATIONS_TRACK-CHANNEL-E";
    /**
     * Доступ к информации о циклах смен
     */
    UserAuthorityEnum["S_CAT_SHFTC_V"] = "S-CAT-SHFTC-V";
    /**
     * Доступ к редактированию информации о циклах смен
     */
    UserAuthorityEnum["S_CAT_SHFTC_E"] = "S-CAT-SHFTC-E";
    /**
     * Доступ к справочнику типов обучений
     */
    UserAuthorityEnum["S_CAT_TRT_MENU"] = "S-CAT-TRT-MENU";
    /**
     * Доступ к данным типов обучений
     */
    UserAuthorityEnum["S_CAT_TRT_V"] = "S-CAT-TRT-V";
    /**
     * Доступ к редактированию типов обучений
     */
    UserAuthorityEnum["S_CAT_TRT_E"] = "S-CAT-TRT-E";
    /**
     * Доступ к справочнику сертификатов
     */
    UserAuthorityEnum["S_CAT_CERT_MENU"] = "S-CAT-CERT-MENU";
    /**
     * Доступ к данным по сертификатам
     */
    UserAuthorityEnum["S_CAT_CERT_V"] = "S-CAT-CERT-V";
    /**
     * Доступ к редактированию данных по сертификатам
     */
    UserAuthorityEnum["S_CAT_CERT_E"] = "S-CAT-CERT-E";
    /**
     * Доступ к редактору карт обхода
     */
    UserAuthorityEnum["ROUTEMAPS_MENU"] = "ROUTEMAPS_MENU";
    /**
     * Доступ к данным по картам обхода
     */
    UserAuthorityEnum["ROUTEMAPS_V"] = "ROUTEMAPS_V";
    /**
     * Доступ к редактированию карт обхода
     */
    UserAuthorityEnum["ROUTEMAPS_E"] = "ROUTEMAPS_E";
    /**
     * Доступ к данным о быстрых фильтрах
     */
    UserAuthorityEnum["QUICK_FILTERS_V"] = "QUICK-FILTERS_V";
    /**
     * Доступ к редактированию данных о быстрых фильтрах
     */
    UserAuthorityEnum["QUICK_FILTERS_E"] = "QUICK-FILTERS_E";
    /**
     * Доступ к быстрым фильтрам по-умолчанию
     */
    UserAuthorityEnum["QUICK_FILTERS_DEFAULT"] = "QUICK-FILTERS_DEFAULT";
    /**
     * Доступ к данным о типах наряд-допусков
     */
    UserAuthorityEnum["WORK_PERMITS_TYPE_V"] = "WORK-PERMITS_TYPE-V";
    /**
     * Доступ к редактору наряд-допусков
     */
    UserAuthorityEnum["WORK_PERMITS_MENU"] = "WORK-PERMITS_MENU";
    /**
     * Доступ к данным о наряд-допусках
     */
    UserAuthorityEnum["WORK_PERMITS_V"] = "WORK-PERMITS_V";
    /**
     * Доступ к редактированию данных о наряд-допусках
     */
    UserAuthorityEnum["WORK_PERMITS_E"] = "WORK-PERMITS_E";
    /**
     * Доступ к редактору маяков
     */
    UserAuthorityEnum["BEACONS_MENU"] = "BEACONS_MENU";
    /**
     * Доступ к просмотру маяков
     */
    UserAuthorityEnum["BEACONS_V"] = "BEACONS_V";
    /**
     * Доступ к редактированию маяков
     */
    UserAuthorityEnum["BEACONS_E"] = "BEACONS_E";
    /**
     * Доступ к списку доступных бизнес-правил
     */
    UserAuthorityEnum["BUSINESS_RULES_V"] = "BUSINESS-RULES_V";
    /**
     * Доступ к редактору бизнес-правил
     */
    UserAuthorityEnum["BUSINESS_RULES_CONFIG_MENU"] = "BUSINESS-RULES_CONFIG-MENU";
    /**
     * Доступ к конфигурациям бизнес-правил
     */
    UserAuthorityEnum["BUSINESS_RULES_CONFIG_V"] = "BUSINESS-RULES_CONFIG-V";
    /**
     * Доступ к редактированию конфигураций бизнес-правил
     */
    UserAuthorityEnum["BUSINESS_RULES_CONFIG_E"] = "BUSINESS-RULES_CONFIG-E";
    /**
     * Доступ к механизму определения применимых бизнес-правил
     */
    UserAuthorityEnum["BUSINESS_RULES_RESOLVER"] = "BUSINESS-RULES_RESOLVER";
    /**
     * Доступ к редактору конструктора бизнес-правил
     */
    UserAuthorityEnum["S_BRC_MENU"] = "S-BRC-MENU";
    /**
     * Доступ к данным конструктора бизнес-правил
     */
    UserAuthorityEnum["S_BRC_V"] = "S-BRC-V";
    /**
     * Доступ к редактированию данных конструктора бизнес-правил
     */
    UserAuthorityEnum["S_BRC_E"] = "S-BRC-E";
    /**
     * Чтение связей нарушений в VIZOR
     */
    UserAuthorityEnum["S_VIZOR_V"] = "S-VIZOR-V";
    /**
     * Редактирование связей нарушений в VIZOR
     */
    UserAuthorityEnum["S_VIZOR_E"] = "S-VIZOR-E";
    /**
     * Просмотр журнала аудита
     */
    UserAuthorityEnum["S_SECAUD_MENU"] = "S-SECAUD-MENU";
    /**
     * Доступ к данны журнала аудита
     */
    UserAuthorityEnum["S_SECAUD_LOG"] = "S-SECAUD-LOG";
    /**
     * Пункт меню Аналитика
     */
    UserAuthorityEnum["S_DASH_MENU"] = "S-DASH-MENU";
    /**
     * Доска
     */
    UserAuthorityEnum["A_DASH_CBRD_E"] = "A-DASH-CBRD-E";
    /**
     * Виджеты
     */
    UserAuthorityEnum["A_DASH_CWDG_E"] = "A-DASH-CWDG-E";
    /**
     * Доступ к просмотру аналитической доски по-умолчанию
     */
    UserAuthorityEnum["S_DASH_DBRD_V"] = "S-DASH-DBRD-V";
    /**
     * Доступ к редактированию аналитической доски по-умолчанию,
     */
    UserAuthorityEnum["S_DASH_DBRD_E"] = "S-DASH-DBRD-E";
    /**
     * Доступ к просмотру виджетов по-умолчанию
     */
    UserAuthorityEnum["S_DASH_DWDG_V"] = "S-DASH-DWDG-V";
    /**
     * Доступ к редактированию виджетов по-умолчанию
     */
    UserAuthorityEnum["S_DASH_DWDG_E"] = "S-DASH-DWDG-E";
    /**
     * Доступ к меню редактирования слоёв
     */
    UserAuthorityEnum["S_LAYM_MENU"] = "S-LAYM-MENU";
    /**
     * Доступ к редактированию внешних слоёв
     */
    UserAuthorityEnum["S_LAYM_EXT_E"] = "S-LAYM-EXT-E";
    /**
     * Доступ к редактированию локальных слоёв
     */
    UserAuthorityEnum["S_LAYM_LOC_E"] = "S-LAYM-LOC-E";
    /**
     * Доступ к тепловой карте
     */
    UserAuthorityEnum["S_MAPR_HEAT"] = "S-MAPR-HEAT";
    /**
     * Доступ к маршрутам отслеживаемого объекта
     */
    UserAuthorityEnum["S_MAPR_TRAC"] = "S-MAPR-TRAC";
    /**
     * Доступ к машине времени
     */
    UserAuthorityEnum["S_MAPR_HIST"] = "S-MAPR-HIST";
    /**
     * Доступ к редактору регулярных отчётов
     */
    UserAuthorityEnum["REPORTS_REG_REPORT_MENU"] = "REPORTS_REG-REPORT-MENU";
    /**
     * Просмотр регулярных отчётов
     */
    UserAuthorityEnum["REPORTS_REG_REPORT_V"] = "REPORTS_REG-REPORT-V";
    /**
     * Редактирование своих регулярных отчётов
     */
    UserAuthorityEnum["REPORTS_REG_REPORT_E"] = "REPORTS_REG-REPORT-E";
    /**
     * Администрирование всех регулярных отчётов
     */
    UserAuthorityEnum["S_REG_RPRT_ADMIN"] = "S-REG-RPRT-ADMIN";
    /**
     * Доступ к странице отчётов
     */
    UserAuthorityEnum["S_RPRT_MENU"] = "S-RPRT-MENU";
    /**
     * Отчет: Время нахождения в зонах по категориям
     */
    UserAuthorityEnum["S_RPRT_01"] = "S-RPRT-01";
    /**
     * Отчет: Общее время нахождения в зонах по датам и категориям
     */
    UserAuthorityEnum["S_RPRT_02"] = "S-RPRT-02";
    /**
     * Oтчет: Порядок обхода зон
     */
    UserAuthorityEnum["S_RPRT_03"] = "S-RPRT-03";
    /**
     * Отчет: Учет общего времени пребывания сотрудников в рабочих зонах
     */
    UserAuthorityEnum["S_RPRT_04"] = "S-RPRT-04";
    /**
     * Отчет: Учет общего времени пребывания сотрудников в периметре строительной площадки
     */
    UserAuthorityEnum["S_RPRT_05"] = "S-RPRT-05";
    /**
     * Отчет: Статистика численности сотрудников по Подрядным организациям
     */
    UserAuthorityEnum["S_RPRT_06"] = "S-RPRT-06";
    /**
     * Отчет: Консолидированный отчет о зафиксированных нарушениях
     */
    UserAuthorityEnum["S_RPRT_07"] = "S-RPRT-07";
    /**
     * Отчет: Результаты обхода за смену
     */
    UserAuthorityEnum["S_RPRT_08"] = "S-RPRT-08";
    /**
     * Отчёт по смене
     */
    UserAuthorityEnum["S_RPRT_09"] = "S-RPRT-09";
    /**
     * Отчёт: "Время нахождения по геозонам"
     */
    UserAuthorityEnum["S_RPRT_10"] = "S-RPRT-10";
    /**
     * Консолидированный отчёт о зафиксированных нарушениях за смену
     */
    UserAuthorityEnum["S_RPRT_11"] = "S-RPRT-11";
    /**
     * Объединённый отчёт по смене
     */
    UserAuthorityEnum["S_RPRT_12"] = "S-RPRT-12";
    /**
     * Отчёт по дежурной службе
     */
    UserAuthorityEnum["S_RPRT_13"] = "S-RPRT-13";
    /**
     * Объединённый отчёт по смене (Версия 2)
     */
    UserAuthorityEnum["S_RPRT_14"] = "S-RPRT-14";
    /**
     * Отчёт по смене (Версия 2)
     */
    UserAuthorityEnum["S_RPRT_15"] = "S-RPRT-15";
    /**
     * Отчёт история использования носимых устройств
     */
    UserAuthorityEnum["S_RPRT_16"] = "S-RPRT-16";
    /**
     * Сотрудники не сдавшие Носимые устройства
     */
    UserAuthorityEnum["S_RPRT_17"] = "S-RPRT-17";
    /**
     * Получение и сдача носимых устройств
     */
    UserAuthorityEnum["S_RPRT_18"] = "S-RPRT-18";
    /**
     * Отчет по журналу событий
     */
    UserAuthorityEnum["S_RPRT_19"] = "S-RPRT-19";
    /**
     * Количество входов и время проведённое в геозонах с бизнес-правилами
     */
    UserAuthorityEnum["S_RPRT_20"] = "S-RPRT-20";
    /**
     * Зафиксированные события "Длительное нахождение на месте"
     */
    UserAuthorityEnum["S_RPRT_21"] = "S-RPRT-21";
    /**
     * Длительная неподвижность и время проведённое в геозонах с бизнес-правилами
     */
    UserAuthorityEnum["S_RPRT_22"] = "S-RPRT-22";
    /**
     * Отчет по сотрудникам сменный (суточный)
     */
    UserAuthorityEnum["S_RPRT_23"] = "S-RPRT-23";
    /**
     * Получение и сдача носимых устройств
     */
    UserAuthorityEnum["S_RPRT_25"] = "S-RPRT-25";
    /**
     * Сотрудники не сдавшие Носимые устройства
     */
    UserAuthorityEnum["S_RPRT_26"] = "S-RPRT-26";
    /**
     * Общее время нахождения в зонах по датам и категориям
     */
    UserAuthorityEnum["S_RPRT_27"] = "S-RPRT-27";
    /**
     * Консолидированный отчет по смене
     */
    UserAuthorityEnum["S_RPRT_28"] = "S-RPRT-28";
    /**
     * Время нахождения по зонам
     */
    UserAuthorityEnum["S_RPRT_29"] = "S-RPRT-29";
    /**
     * Количество входов и время проведённое в геозонах с бизнес-правилами
     */
    UserAuthorityEnum["S_RPRT_30"] = "S-RPRT-30";
    /**
     * Учет общего времени пребывания
     */
    UserAuthorityEnum["S_RPRT_31"] = "S-RPRT-31";
    /**
     * Отчет о событиях газовой безопасности
     */
    UserAuthorityEnum["S_RPRT_32"] = "S-RPRT-32";
})(UserAuthorityEnum || (UserAuthorityEnum = {}));
export default UserAuthorityEnum;
