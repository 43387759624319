import TagType from "./TagType";
export default class ProtectionEquipmentType extends TagType {
    constructor(dto) {
        super(dto);
    }
    static initNew() {
        return {
            ts: -1,
        };
    }
}
