var TimeAssignmentsEnum;
(function (TimeAssignmentsEnum) {
    /**
     * Категории зон
     */
    TimeAssignmentsEnum[TimeAssignmentsEnum["ZoneCategories"] = 1] = "ZoneCategories";
    /**
     * Геозоны
     */
    TimeAssignmentsEnum[TimeAssignmentsEnum["Geofences"] = 2] = "Geofences";
})(TimeAssignmentsEnum || (TimeAssignmentsEnum = {}));
export default TimeAssignmentsEnum;
