import Versioned from "./Versioned";
export default class Group extends Versioned {
    constructor(group) {
        super(group);
        this.name = group.name;
        this.trackables = group.trackables;
        this.persons = group.persons;
        this.status = group.status;
        this.externalId = group.externalId;
    }
}
