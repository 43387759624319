export default class ZoneState {
    constructor(value) {
        if (!value.id) {
            throw new Error("Id is not defined");
        }
        this.id = value.id;
        this.code = value.code;
        this.description = value.description;
    }
}
ZoneState.typeName = "ZoneState";
