import Base from "./Base";
class TrainingType extends Base {
    constructor(data) {
        super(data);
        this.code = data.code;
        this.name = data.name;
        this.description = data.description;
        this.status = data.status;
        this.isSynchronized = data.isSynchronized;
        this.synchronizedId = data.synchronizedId;
    }
}
export default TrainingType;
