import BaseEditableObject from "./BaseEditableObject";
class VersionedEditableObject extends BaseEditableObject {
    constructor({ createdAt, deletedAt, lastModifiedAt, lastModifiedBy, ...rest } = {
        createdAt: new Date(),
    }) {
        super(rest);
        this.createdAt = createdAt;
        this.deletedAt = deletedAt;
        this.lastModifiedAt = lastModifiedAt;
        this.lastModifiedBy = lastModifiedBy;
    }
}
export default VersionedEditableObject;
