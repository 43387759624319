import BaseEditableObject from "./BaseEditableObject";
class EditableViewPoint extends BaseEditableObject {
    constructor(args) {
        super(args);
        this.name = args.name;
        this.orientation = args.orientation;
        this.destination = args.destination;
    }
}
export default EditableViewPoint;
