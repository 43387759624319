import Versioned from "./Versioned";
export default class TagType extends Versioned {
    constructor(data) {
        super(data);
        if (!data.id) {
            throw new Error("Id is not defined");
        }
        this.id = data.id;
        this.code = data.code;
        this.name = data.name;
        this.ts = data.ts;
        this.status = data.status;
    }
}
TagType.typeName = "TagType";
