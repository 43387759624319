export default class StorageReport {
    constructor({ created, path, state, reportType, type, mime, from, to }) {
        this.created = created && created.toString();
        this.path = path;
        this.from = from && from.toString();
        this.to = to && to.toString();
        this.state = state;
        this.reportType = reportType;
        this.type = type;
        this.mime = mime;
    }
}
