import Versioned from "./Versioned";
import RoutePoint from "./RoutePoint";
export default class RouteMap extends Versioned {
    constructor(data) {
        super(data);
        this.active = data.active;
        this.name = data.name;
        this.deployments = data.deployments;
        this.shiftCycleId = data.shiftCycleId;
        this.daysOfWeek = data.daysOfWeek;
        this.points = data.points.map((value) => new RoutePoint(value)).sort((a) => a.orderNumber);
    }
}
