import Versioned from "./Versioned";
import Shift from "./Shift";
export default class ShiftCycle extends Versioned {
    constructor(data) {
        super(data);
        this.name = data.name;
        this.startingTime = new Date(data.startingTime);
        this.shifts = data.shifts.map((shift) => new Shift(shift)).sort((a) => a.orderNumber);
    }
}
