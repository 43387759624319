import LayerDatasourceAttribute from "./LayerDatasourceAttribute";
export default class LayerDatasource {
    constructor(data) {
        this.id = data.id;
        this.ts = data.ts;
        this.type = data.type;
        this.name = data.name;
        this.attributes = data.attributes.map((it) => new LayerDatasourceAttribute(it));
    }
}
