import Zone from "./Zone";
class Building extends Zone {
    constructor(data) {
        super(data);
        this.address = data.address;
        this.floorsCount = data.floorsCount;
    }
}
Building.typeName = "Building";
export default Building;
