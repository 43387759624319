import BaseEditableObject from "./BaseEditableObject";
import EditableZone from "./EditableZone";
class EditableEquipment extends BaseEditableObject {
    constructor({ zone, name, inspectorsNumber, ...args }, viewPoints) {
        super(args);
        this.name = name;
        this.inspectorsNumber = inspectorsNumber;
        this.zone = zone && new EditableZone(zone, viewPoints);
    }
    static prepareToSave(before, after) {
        if (before) {
            return {
                ...after,
                zone: before.zone ? EditableZone.prepareToSave(before.zone, after.zone) : after.zone,
            };
        }
        return after;
    }
}
export default EditableEquipment;
