import BaseEditableObject from "./BaseEditableObject";
class EditableCertificate extends BaseEditableObject {
    constructor(data = {}) {
        super(data);
        this.trainingTypeId = data.trainingTypeId;
        this.number = data.number;
        this.learningCenter = data.learningCenter;
        this.dateOfIssue = data.dateOfIssue;
        this.dateOfExpiration = data.dateOfExpiration;
        this.personIds = data.personIds;
        this.personId = data.personId;
    }
}
export default EditableCertificate;
