import Group from "./Group";
import Contacts from "./Contacts";
export default class Organization extends Group {
    constructor(organization) {
        super(organization);
        this.color = organization.color;
        this.departments = organization.departments;
        this.deployments = organization.deployments;
        this.contacts = new Contacts(organization.contacts);
        this.syncEnabled = organization.syncEnabled;
    }
}
Organization.NO_ORGANIZATION_ID = "NO_ORGANIZATION";
