import BaseEditableObject from "./BaseEditableObject";
import EditableRoutePoint from "./EditableRoutePoint";
class EditableRouteMap extends BaseEditableObject {
    constructor(args, viewPoints) {
        super(args);
        this.active = args.active;
        this.name = args.name;
        this.deployments = args.deployments;
        this.shiftCycleId = args.shiftCycleId;
        this.daysOfWeek = args.daysOfWeek;
        this.selectedPoint = args.selectedPoint ?? -1;
        this.selectedTab = args.selectedTab ?? 0;
        this.points = args.points?.map((value) => new EditableRoutePoint(value, viewPoints));
    }
    static prepareToSave(before, after) {
        if (before) {
            return {
                ...after,
                points: before.points
                    ? after.points.map((value) => EditableRoutePoint.prepareToSave(before.points.find((point) => point.id === value.id), value))
                    : after.points,
            };
        }
        return after;
    }
}
export default EditableRouteMap;
