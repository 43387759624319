var MetricsEnum;
(function (MetricsEnum) {
    /**
     * Сотрудники
     */
    MetricsEnum["Person"] = "P";
    /**
     * Время
     */
    MetricsEnum["Time"] = "T";
    /**
     * События
     */
    MetricsEnum["Event"] = "E";
    /**
     * Устройства
     */
    MetricsEnum["Device"] = "D";
    /**
     * Техника
     */
    MetricsEnum["Vehicle"] = "V";
})(MetricsEnum || (MetricsEnum = {}));
export default MetricsEnum;
