class EditableRelay {
    constructor(args) {
        this.id = args.id;
        this.name = args.name;
        this.zoneId = args.zoneId;
        this.ipAddress = args.ipAddress;
        this.port = args.port;
    }
}
export default EditableRelay;
