class UserRole {
    constructor({ id, name, ts }) {
        if (!id) {
            throw new Error("Id is not defined");
        }
        this.id = id;
        this.name = name;
        this.ts = ts;
    }
}
export default UserRole;
