import { ComponentType, ErrorInfo, Fragment, PureComponent } from "react";
import ErrorMessage from "./ErrorMessage";

type ErrorHandlerState = {
    error: Error;
    raised: number;
};

export class ErrorHandler extends PureComponent<{ displayError?: boolean }, ErrorHandlerState> {
    static defaultProps = {
        displayError: true,
    };

    state: ErrorHandlerState = {
        error: null,
        raised: 0,
    };

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        this.setState((state) => ({
            error,
            raised: state.raised + 1,
        }));
        console.error(errorInfo);
    }

    render() {
        if (this.state.error && this.props.displayError) {
            return <ErrorMessage error={this.state.error.message} />;
        }
        return <Fragment key={this.state.raised}>{this.props.children}</Fragment>;
    }
}

function withErrorHandling<P>(Component: ComponentType<P>, displayError = true): ComponentType<P> {
    return function (props: P) {
        return (
            <ErrorHandler displayError={displayError}>
                <Component {...props} />
            </ErrorHandler>
        );
    };
}

export default withErrorHandling;
