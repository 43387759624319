import Versioned from "./Versioned";
import { TagProfileStatus } from "@croc.drpo_rnd/backend-api";
export default class TagProfile extends Versioned {
    constructor(dto = {}) {
        super(dto);
        this.name = dto.name;
        this.status = dto.status;
        this.isAssigned = false;
        this.equipmentPositions = { ...dto.equipmentPositions };
    }
    static initNew() {
        const equipmentPositions = {};
        Array.from(Array(8)).forEach((v, i) => {
            equipmentPositions[i.toString()] = null;
        });
        return {
            isAssigned: false,
            status: TagProfileStatus.ACTIVE,
            ts: -1,
            equipmentPositions,
        };
    }
    /**
     * Конвертирует редактируемый профиль обратно в обычный, который можно сохранить на сервер.
     *
     * То есть превращает массив типов СИЗов обратно в мапу.
     *
     * @param tagProfile
     */
    static convertFromEditable(tagProfile) {
        const saveablePositions = {};
        tagProfile.equipmentPositions.forEach((position, index) => {
            if (position) {
                saveablePositions[index.toString()] = position.id;
            }
        });
        return {
            ...tagProfile,
            equipmentPositions: saveablePositions,
        };
    }
    /**
     * Конвертирует профиль в редактируемый.
     *
     * Редактируемость заключается в том, что мы превращаем серверную мапу в массив типов СИЗов.
     * Это сделано, так как react-final-form не прнимает объекты с номерными ключами, только массивы :(.
     *
     * @param tagProfile
     */
    static convertToEditable(tagProfile, tagTypes) {
        const editablePositions = new Array(8).fill(null);
        Object.entries(tagProfile.equipmentPositions).forEach(([index, value]) => {
            const indexNumber = Number.parseInt(index);
            const tagType = tagTypes.find((tt) => tt.id === value);
            editablePositions[indexNumber] = tagType;
        });
        return {
            ...tagProfile,
            equipmentPositions: editablePositions,
        };
    }
}
