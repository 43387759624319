import Versioned from "./Versioned";
import Coords from "./Coords";
import SensorStatusEnum from "../enums/SensorStatusEnum";
export default class Sensor extends Versioned {
    constructor(sensor) {
        super(sensor);
        this.type = sensor.type;
        this.name = sensor.name;
        this.vendorId = sensor.vendorId;
        this.externalSystemId = sensor.externalSystemId;
        this.zoneId = sensor.zoneId;
        this.position = new Coords(sensor.position);
        this.status = SensorStatusEnum[sensor.status];
    }
}
