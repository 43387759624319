class NotificationDelivery {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.groupId = data.groupId;
        this.groupName = data.groupName;
        this.active = data.active;
        this.readonly = data.readonly;
    }
}
export default NotificationDelivery;
