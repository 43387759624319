import BaseEditableObject from "./BaseEditableObject";
class EditableBeacon extends BaseEditableObject {
    constructor(args = {}) {
        super(args);
        this.txPower = -72;
        this.name = args.name;
        this.position = args.position;
        this.ibeaconEnabled = args.ibeaconEnabled;
        this.eddystoneInstance = args.eddystoneInstance;
        this.eddystoneNamespace = args.eddystoneNamespace;
        this.eddystoneEnabled = args.eddystoneEnabled;
        this.ibeaconMajor = args.ibeaconMajor;
        this.ibeaconMinor = args.ibeaconMinor;
        this.ibeaconUUID = args.ibeaconUUID;
        this.mac = args.mac;
        this.txPower = args.txPower;
    }
}
export default EditableBeacon;
