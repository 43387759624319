import TagHistoryRecord from "./TagHistoryRecord";
export default class TagHistory {
    constructor(item) {
        this.history = [];
        this.id = item.id;
        this.name = item.name;
        this.tagSerialNumber = item.tagSerialNumber;
        for (const historyItem of item.history) {
            this.history.push(new TagHistoryRecord(historyItem));
        }
    }
}
