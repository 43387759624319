import Coords from "./Coords";
export default class Point {
    constructor(data, altitude) {
        const { number, latitude, longitude } = data;
        this.number = number;
        this.coords =
            latitude &&
                longitude &&
                new Coords({
                    longitude,
                    latitude,
                    altitude,
                });
    }
}
