import Base from "./Base";
class Certificate extends Base {
    constructor(data) {
        super(data);
        this.number = data.number;
        this.trainingTypeId = data.trainingTypeId;
        this.personId = data.personId;
        this.learningCenter = data.learningCenter;
        this.dateOfIssue = data.dateOfIssue ? new Date(data.dateOfIssue) : null;
        this.dateOfExpiration = data.dateOfExpiration ? new Date(data.dateOfExpiration) : null;
        this.status = data.status;
        this.isSynchronized = data.isSynchronized;
        this.synchronizedId = data.synchronizedId;
    }
}
export default Certificate;
