class Position {
    constructor(data) {
        const { x, y, w, h } = (data && JSON.parse(data)) || {};
        this.x = x != null ? x : 0;
        this.y = y != null ? y : 0;
        this.w = w != null ? w : 1;
        this.h = h != null ? h : 1;
    }
}
export default class Widget {
    constructor({ position, parameters, ...data }) {
        this.deleted = false;
        this.isNew = false;
        this.id = data.id && data.id.toString();
        this.code = data.code;
        this.refreshPeriod = data.refreshPeriod;
        this.title = data.title;
        this.ts = data.ts;
        this.type = data.type;
        this.position = new Position(position);
        this.parameters = {
            filter: {},
            size: {},
            ...(parameters && JSON.parse(parameters)),
        };
    }
}
