export default class Layer {
    constructor(data) {
        this.id = data.id;
        this.ts = data.ts;
        this.name = data.name;
        this.url = data.url;
        this.datasourceId = data.datasourceId;
        this.base = data.base;
        this.show = data.show;
    }
}
