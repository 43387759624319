import PersonLink from "./PersonLink";
import UserRole from "./UserRole";
import Versioned from "./Versioned";
class User extends Versioned {
    constructor(data = {}) {
        super(data);
        const { login, person, defaultZoneId, roles = [] } = data;
        this.login = login;
        this.person = person && new PersonLink(person);
        this.roles = roles.map((value) => new UserRole(value));
        this.defaultZoneId = defaultZoneId;
        this.status = data.status;
    }
}
export default User;
