import { Component } from "react";
import PropTypes from "prop-types";
import { exactProp } from "@material-ui/utils";
import { createStyles, withStyles } from "@material-ui/styles";
import { CustomTheme } from "@croc.drpo_rnd/ui-kit";

const styles = ({ colors, palette }: CustomTheme) =>
    createStyles({
        "@global": {
            "*::-webkit-scrollbar-track": {
                "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
            },
            "*::-webkit-scrollbar": {
                width: 4,
                height: 4,
            },
            "*::-webkit-scrollbar-thumb": {
                background: colors.scrollbar,
            },
            "*::-webkit-scrollbar-corner": {
                background: palette.background.paper,
            },
        },
    });

/**
 * Kickstart an elegant, consistent, and simple baseline to build upon.
 */
class CssMaterialScrollbar extends Component {
    static defaultProps = {
        children: null,
    };

    static propTypes = {
        /**
         * You can wrap a node.
         */
        children: PropTypes.node,
        /**
         * @ignore
         */
        classes: PropTypes.object.isRequired,
    };

    render() {
        return this.props.children;
    }
}

if (process.env.NODE_ENV !== "production") {
    CssMaterialScrollbar.propTypes = exactProp(CssMaterialScrollbar.propTypes);
}

export default withStyles(styles, { name: "MuiCssScrollbar" })(CssMaterialScrollbar);
