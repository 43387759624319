class Location {
    constructor(location) {
        if (!location.id) {
            throw new Error("Id is not defined");
        }
        this.id = location.id;
        this.vendorLocationId = location.externalId;
        this.vendorId = location.externalSystem;
        this.name = location.name;
        this.position = {
            altitude: location.altitude,
            latitude: location.latitude,
            longitude: location.longitude,
        };
        this.mapRotationAngle = location.rotation;
        this.extraOptions = location.additionalParameter;
        this.ts = location.ts;
    }
}
Location.typeName = "Location";
export default Location;
