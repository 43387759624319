var PlaybackSpeedEnum;
(function (PlaybackSpeedEnum) {
    PlaybackSpeedEnum[PlaybackSpeedEnum["x1"] = 1] = "x1";
    PlaybackSpeedEnum[PlaybackSpeedEnum["x2"] = 2] = "x2";
    PlaybackSpeedEnum[PlaybackSpeedEnum["x8"] = 8] = "x8";
    PlaybackSpeedEnum[PlaybackSpeedEnum["x32"] = 32] = "x32";
    PlaybackSpeedEnum[PlaybackSpeedEnum["x100"] = 100] = "x100";
    PlaybackSpeedEnum[PlaybackSpeedEnum["x500"] = 500] = "x500";
    PlaybackSpeedEnum[PlaybackSpeedEnum["x1000"] = 1000] = "x1000";
})(PlaybackSpeedEnum || (PlaybackSpeedEnum = {}));
export default PlaybackSpeedEnum;
