var NotifyEmployeeContextType;
(function (NotifyEmployeeContextType) {
    /**
     * Сотрудник.
     */
    NotifyEmployeeContextType["PERSON"] = "PERSON";
    /**
     * Событие.
     */
    NotifyEmployeeContextType["EVENT"] = "EVENT";
    /**
     * Геозона.
     **/
    NotifyEmployeeContextType["ZONE"] = "ZONE";
})(NotifyEmployeeContextType || (NotifyEmployeeContextType = {}));
export default NotifyEmployeeContextType;
