import Group from "./Group";
export default class Deployment extends Group {
    constructor(deployment) {
        super(deployment);
        this.position = deployment.position;
        this.department = deployment.department;
        this.organization = deployment.organization;
        this.peculiarities = deployment.peculiarities;
        this.syncEnabled = deployment.syncEnabled;
        this.externalId = deployment.externalId;
    }
}
