import Coords from "./Coords";
export default class Incident {
    constructor({ id, zoneId, typeName, occured, priority, status, state, position }) {
        this.id = id;
        this.zoneId = zoneId;
        this.typeName = typeName;
        this.occured = new Date(occured);
        this.priority = priority;
        this.status = status;
        this.state = state;
        this.position = position && new Coords(position);
    }
}
