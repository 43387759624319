import { Domain } from "@dwclient/domain";
export function parseCookies() {
    let cookieObject = {}, i, group, idx, key;
    if (typeof document === "undefined") {
        return cookieObject;
    }
    const cookiesArray = document.cookie ? document.cookie.split("; ") : [];
    for (i = 0; i < cookiesArray.length; i = i + 1) {
        group = cookiesArray[i];
        idx = group.indexOf("=");
        key = decodeURIComponent(group.substr(0, idx));
        cookieObject[key] || (cookieObject[key] = decodeURIComponent(group.substr(idx + 1)));
    }
    return cookieObject;
}
const lang = parseCookies()["X-Lang"];
const processEnv = process.env;
let windowEnv = {};
if (typeof window !== "undefined") {
    // @ts-ignore
    windowEnv = window._env_ || {};
    for (let key of Object.keys(windowEnv)) {
        if (windowEnv[key] === "") {
            delete windowEnv[key];
        }
    }
}
{
    console.debug("process.env", processEnv);
    console.debug("windowEnv", windowEnv);
}
const env = {
    ...processEnv,
    ...windowEnv,
};
console.debug("Environment combination: ", env);
export const locale = lang || env.LOCALE || Domain.ClientLocale.ru;
// Настройки для соединения с API
export const baseUrl = env.REACT_APP_API_BASE_URL || "/";
/**
 * Пути к точкам доступа API разных сервисов
 */
export const endpoints = {
    reports: env.REACT_APP_REPORTS_URL || "/",
    backend: env.REACT_APP_BACKEND_URL || "/",
    notifications: env.REACT_APP_NOTIFICATIONS_URL || "/",
    businessRules: env.REACT_APP_BUSINESS_RULES_URL || "/",
    application: env.REACT_APP_APPLICATION_URL || "/",
    vizorCategory: env.REACT_APP_VIZOR_CATEGORY_URL || "/",
    relay: env.REACT_APP_RELAY_URL || "/",
};
export const startUrl = env.REACT_APP_START_URL || "/";
export const localLayerUrl = env.REACT_APP_LOCAL_LAYER_URL;
export const rtspProxy = env.REACT_APP_RTSP_PROXY;
export const deviceHostUrl = env.REACT_APP_DEVICE_HOST;
export const supportEmail = env.REACT_APP_SUPPORT_EMAIL;
export const trackTimeGap = Number.parseInt(env.REACT_APP_TRACK_TIME_GAP_MS);
export const wsReconnectDelay = Number.parseInt(env.REACT_APP_WS_RECONNECT_DELAY);
export const wsHeartbeatIncoming = Number.parseInt(env.REACT_APP_WS_HEARTBEAT_INCOMING);
export const wsHeartbeatOutgoing = Number.parseInt(env.REACT_APP_WS_HEARTBEAT_OUTGOING);
export const watermarkUrl = env.REACT_APP_WATERMARK_URL;
export const disableWatermark = env.REACT_APP_DISABLE_WATERMARK === "true";
export const faviconUrl = env.REACT_APP_FAVICON_URL;
export const appTitle = env.REACT_APP_TITLE;
export const isDeviceIssuesTabDisabled = env.REACT_APP_DISABLE_DEVICE_ISSUES_TAB === "true";
export const isHeatmapBusinessRuleFieldDisabled = env.REACT_APP_DISABLE_HEATMAP_BUSINESS_RULE_FIELD === "true";
export const isDevicesPageControlsDisabled = env.REACT_APP_DISABLE_DEVICES_PAGE_CONTROLS === "true";
export const isVizorCategoryEnabled = env.REACT_APP_VIZOR_CATEGORY_ENABLED === "true";
export const isUserCatalogEditingDisabled = env.REACT_APP_DISABLE_USER_CATALOG_EDITING === "true";
export const recommendedMessageMaxLength = Number.parseInt(env.REACT_APP_RECOMMENDED_MESSAGE_MAX_LENGTH) || 50;
export const notificationDefaultPeculiarities = env.REACT_APP_NOTIFICATION_DEFAULT_PECULIARITIES === undefined
    ? []
    : env.REACT_APP_NOTIFICATION_DEFAULT_PECULIARITIES.split(",").map((code) => code.trim());
// Настройки для работы с keycloak
export const keycloakBaseUrl = env.REACT_APP_KEYCLOAK_BASE_URL || "/";
export const keycloakRealm = env.REACT_APP_KEYCLOAK_REALM || "whswd";
export const keycloakClientId = env.REACT_APP_KEYCLOAK_CLIENT_ID || "app";
export const authUrl = keycloakBaseUrl;
export const tagTypesSupportProfile = env.REACT_APP_TAG_TYPE_SUPPORT_PROFILE === undefined
    ? []
    : env.REACT_APP_TAG_TYPE_SUPPORT_PROFILE.split(",").map((code) => code.trim().toLowerCase());
//Запускает постоянное обновление сотрудника, если нужно посмотреть под нагрузкой.
export const moving = false;
export const overridesThemeOptions = (function () {
    try {
        return (env["REACT_APP_THEMES"] && JSON.parse(env["REACT_APP_THEMES"])) || {};
    }
    catch (e) {
        console.error(e);
        return {};
    }
})();
//Настройки карты
export const mapTrustedServerUrl = env.REACT_APP_MAP_TRUSTED_SERVER_URL;
export const mapTrustedServerPort = env.REACT_APP_MAP_TRUSTED_SERVER_PORT;
console.debug("------ Config ------");
console.debug("baseUrl: ", baseUrl);
console.debug("endpoints: ", endpoints);
console.debug("startUrl: ", startUrl);
console.debug("localLayerUrl: ", localLayerUrl);
console.debug("rtspProxy: ", rtspProxy);
console.debug("deviceHostUrl: ", deviceHostUrl);
console.debug("supportEmail: ", supportEmail);
console.debug("trackTimeGap: ", trackTimeGap);
console.debug("wsReconnectDelay: ", wsReconnectDelay);
console.debug("wsHeartbeatIncoming: ", wsHeartbeatIncoming);
console.debug("wsHeartbeatOutgoing: ", wsHeartbeatOutgoing);
console.debug("keycloakBaseUrl: ", keycloakBaseUrl);
console.debug("keycloakRealm: ", keycloakRealm);
console.debug("keycloakClientId: ", keycloakClientId);
console.debug("authUrl: ", authUrl);
console.debug("moving: ", moving);
console.debug("overridesThemeOptions: ", overridesThemeOptions);
console.debug("mapTrustedServerUrl: ", mapTrustedServerUrl);
console.debug("mapTrustedServerPort: ", mapTrustedServerPort);
