export default class DWDirectory {
    constructor(data) {
        this.equals = (directory) => directory && (directory === this || this.path === directory.path);
        if (data) {
            this.name = data.name;
            this.created = data.createdAt
                ? new Date(data.createdAt)
                : data instanceof DWDirectory
                    ? data.created
                    : null;
            this.path = data.path;
            this.isDefault = false;
        }
        else {
            this.name = null;
            this.created = null;
            this.path = null;
            this.isDefault = true;
        }
    }
    getDirectory() {
        return this.isDefault ? null : this.path.split("/").slice(1).join("/");
    }
}
DWDirectory.defaultFolder = new DWDirectory(null);
DWDirectory.getDefault = () => DWDirectory.defaultFolder;
