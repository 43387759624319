import ModelInfo from "./ModelInfo";
import Versioned from "./Versioned";
export default class Trackable extends Versioned {
    constructor(trackable, baseUrl) {
        super(trackable);
        this.type = Trackable.typeName;
        this.name = trackable.name;
        this.model = trackable.model && new ModelInfo(trackable.model, baseUrl);
        this.tags = trackable.tags;
        this.groups = trackable.groups;
        this.mainGroupId = trackable.mainGroupId;
    }
}
Trackable.typeName = "Trackable";
