import Label from "./Label";
import Point from "./Point";
import Coords from "./Coords";
import Base from "./Base";
class Zone extends Base {
    //todo: Перенести на бэк calcZoneParameters
    constructor(zone) {
        super(zone);
        this.type = zone.type;
        this.parent = zone.parent || (zone.id !== Zone.META_ZONE_ID ? Zone.META_ZONE_ID : null);
        this.name = zone.name;
        this.viewPoint = zone.viewPoint;
        this.altitude = zone.altitude;
        this.height = zone.height;
        this.zoneModels = zone.zoneModels || [];
        this.zoneBusinessRules = zone.zoneBusinessRules || [];
        this.zoneTimeUtilization = zone.zoneTimeUtilization;
        this.isWorkingZone = zone.workingZone;
        try {
            this.label = zone.label && new Label(zone.label);
        }
        catch (e) {
            throw new Error("Invalid Label data: " + e.message);
        }
        this.selectionType = zone.selectionType;
        this.level = zone.level;
        this.workPermitId = zone.workPermitId;
        this.createdAt = zone.createdAt && new Date(zone.createdAt);
        this.deletedAt = zone.deletedAt && new Date(zone.deletedAt);
        this.polygon = zone.polygon ? zone.polygon.map((value) => new Point(value, this.altitude)) : [];
        this.center = zone.center && new Coords(zone.center);
        this.radius = zone.radius;
    }
    static isActual(zone, currentTime = new Date()) {
        return !zone.deletedAt || zone.deletedAt.getTime() > currentTime.getTime();
    }
    static compare(z1, z2) {
        if (Zone.isActual(z1) !== Zone.isActual(z2)) {
            return Zone.isActual(z1) ? -1 : 1;
        }
        return z1.name.localeCompare(z2.name, undefined, { numeric: true });
    }
}
Zone.typeName = "Zone";
Zone.META_ZONE_ID = "00000000-0000-0000-0000-000000000000";
/**
 * Идентификатор фиктивной зоны, который используется в виджетах для передачи инф-ции по объектам,
 * статус подключения которых = "отсутствует сигнал"
 */
Zone.NO_SIGNAL_ZONE_ID = "NO_SIGNAL";
export default Zone;
