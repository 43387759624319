import { overridesThemeOptions } from "@dwclient/config";
import { ThemeProvider } from "@material-ui/styles";
import {
    createCustomMuiTheme,
    CustomTheme,
    CustomThemeOptions,
    darkThemeOptions,
    lightThemeOptions,
} from "@croc.drpo_rnd/ui-kit";
import CssMaterialScrollbar from "../components/CssMaterialScrollbar";
import type { PropsWithChildren } from "react";
import { CssBaseline } from "@material-ui/core";
import logoLight from "@croc.drpo_rnd/ui-kit/logo/logo-light.svg";
import logoDark from "@croc.drpo_rnd/ui-kit/logo/logo-dark.svg";
import { overrideObjects } from "../utils/dataUtils";

const darkThemeOptionsWithLogo: CustomThemeOptions = {
    ...darkThemeOptions,
    logo: {
        path: logoDark,
        size: {
            width: 80,
            height: 20,
        },
    },
};
const lightThemeOptionsWithLogo: CustomThemeOptions = {
    ...lightThemeOptions,
    logo: {
        path: logoLight,
        size: {
            width: 80,
            height: 20,
        },
    },
};

const getMuiThemes = (darkTheme, lightTheme, externalThemes): Record<string, CustomTheme> => {
    if (!Object.keys(externalThemes).length)
        return {
            dark: createCustomMuiTheme(darkTheme),
            light: createCustomMuiTheme(lightTheme),
        };

    const overriddenDark = externalThemes.dark
        ? { dark: createCustomMuiTheme(overrideObjects(darkTheme, externalThemes.dark)) }
        : {};
    const overriddenLight = externalThemes.light
        ? { light: createCustomMuiTheme(overrideObjects(lightTheme, externalThemes.light)) }
        : {};

    return {
        ...overriddenDark,
        ...overriddenLight,
    };
};

const themes = getMuiThemes(darkThemeOptionsWithLogo, lightThemeOptionsWithLogo, overridesThemeOptions);

export type Themes = keyof typeof themes;
export const themeNames = Object.keys(themes) as Themes[];
export const defaultTheme = themeNames[0];

const CustomThemeProvider = ({ theme, children }: PropsWithChildren<{ theme: Themes }>) => (
    <ThemeProvider theme={themes[theme]}>
        <CssBaseline />
        <CssMaterialScrollbar />
        {children}
    </ThemeProvider>
);

export default CustomThemeProvider;
