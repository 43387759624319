import Group from "./Group";
import Contacts from "./Contacts";
export default class Department extends Group {
    constructor(department) {
        super(department);
        this.parentDepartment = department.parentDepartment;
        this.parentOrganization = department.parentOrganization;
        this.departments = department.departments;
        this.contacts = new Contacts(department.contacts);
        this.deployments = department.deployments;
        this.syncEnabled = department.syncEnabled;
    }
}
