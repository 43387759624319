import "./polyfills";
import { StrictMode } from "react";
import { render } from "react-dom";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import App from "./App";
import "@croc.drpo_rnd/ui-kit/fonts/rubik/rubik.css";
import "./styles/index.css";
//todo: Избавиться
import "./styles/cardContainer.css";
import "./styles/personCard.css";

render(
    <StrictMode>
        <App />
    </StrictMode>,
    document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
reportWebVitals((metric) => console.debug(metric));
