import EditablePoint from "./EditablePoint";
import EditableViewPoint from "./EditableViewPoint";
import BaseEditableObject from "./BaseEditableObject";
class EditableZone extends BaseEditableObject {
    constructor({ height, polygon = [], zoneBusinessRules = [], zoneTimeUtilization, viewPoint, name, altitude, parent, ...args } = {}, viewPoints) {
        super(args);
        this.polygon = polygon.sort((a, b) => a.number - b.number).map((value) => new EditablePoint(value));
        this.height = height || 0;
        let viewPointObject;
        if (typeof viewPoint === "string") {
            viewPointObject = viewPoints.find((value) => viewPoint === value.id);
        }
        else {
            viewPointObject = viewPoint;
        }
        this.zoneBusinessRules = zoneBusinessRules;
        this.zoneTimeUtilization = zoneTimeUtilization;
        this.viewPoint = viewPointObject && new EditableViewPoint(viewPointObject);
        this.name = name;
        this.altitude = altitude;
        this.parent = parent;
    }
    static prepareToSave(before, after) {
        if (!before) {
            return after;
        }
        const isNewPolygon = before.height !== after.height ||
            before.altitude !== after.altitude ||
            JSON.stringify(before.polygon) !== JSON.stringify(after.polygon);
        return {
            ...after,
            height: isNewPolygon ? after.height : null,
            altitude: isNewPolygon ? after.altitude : null,
            polygon: isNewPolygon ? after.polygon : null,
        };
    }
}
export default EditableZone;
