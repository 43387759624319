class IncidentType {
    constructor(value) {
        if (!value.id) {
            throw new Error("Id is not defined");
        }
        this.id = value.id;
        this.name = value.name;
        this.code = value.code;
        this.animations = value.animations;
        this.ts = value.ts;
    }
}
IncidentType.typeName = "IncidentType";
export default IncidentType;
