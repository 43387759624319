import WorkerMessage from "./WorkerMessage";
import { BehaviorSubject } from "rxjs";

type ValueType<Message extends WorkerMessage> = Message extends WorkerMessage<string, infer V> ? V : never;

class SerializedWorker {
    private readonly subject: BehaviorSubject<WorkerMessage | null> = new BehaviorSubject(null);

    constructor(private worker: Worker, private timeout: number) {
        this.worker.addEventListener("message", this.onReceiveMessage);
    }

    private onReceiveMessage = (ev: MessageEvent<WorkerMessage>) => {
        try {
            const message = ev?.data;
            this.subject.next(message);
        } catch (e) {
            console.error(e);
        }
    };

    protected sendData(message: WorkerMessage) {
        this.worker.postMessage(message);
    }

    public handleData<R extends WorkerMessage>(caller: string) {
        return new Promise<ValueType<R>>((resolve, reject) => {
            const timeout = setTimeout(() => {
                subscription.unsubscribe();
                reject("Timeout");
            }, this.timeout);
            const subscription = this.subject.subscribe((message) => {
                if (message && message.caller === caller) {
                    clearTimeout(timeout);
                    subscription.unsubscribe();
                    if (message.data) {
                        resolve(message.data);
                    } else {
                        reject(message.error);
                    }
                }
            });
        });
    }

    public terminate() {
        this.worker.removeEventListener("message", this.onReceiveMessage);
        this.worker.terminate();
    }
}

export default SerializedWorker;
