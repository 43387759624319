import Base from "./Base";
export default class Versioned extends Base {
    constructor(data) {
        super(data);
        if (data) {
            this.createdAt = new Date(data.createdAt);
            this.deletedAt = data.deletedAt ? new Date(data.deletedAt) : null;
            this.lastModifiedAt = data.lastModifiedAt && new Date(data.lastModifiedAt);
            this.lastModifiedBy = data.lastModifiedBy;
        }
    }
    static isActive(entity, date = new Date()) {
        return !entity.deletedAt || entity.deletedAt > date;
    }
    static getModifiedByName(entity) {
        if (!entity.lastModifiedBy)
            return "";
        if (entity.lastModifiedBy.lastName && entity.lastModifiedBy.firstName) {
            const firstNameShort = `${entity.lastModifiedBy.firstName[0]}.`;
            const middleNameShort = entity.lastModifiedBy.middleName?.[0]
                ? `${entity.lastModifiedBy.middleName[0]}.`
                : "";
            return `${entity.lastModifiedBy.lastName} ${firstNameShort} ${middleNameShort}`;
        }
        return entity.lastModifiedBy.login;
    }
}
