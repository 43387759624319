import { declOfNum } from "./index";
import { DashboardTimeUnitEnum, DeviceAssignmentsEnum, EventAssignmentsEnum, MetricsEnum, PersonAssignmentsEnum, TimeAssignmentsEnum, VehicleAssignmentsEnum, } from "@dwclient/domain";
import EN_CONTROL_RESOURCES from "./control/en";
import enEditorResources from "./editors/en";
import enEnumResources from "./enums/en";
import panelsEn from "./panels/en";
const enResources = {
    zoneCard: {
        zoneTimeUtilization: "Category",
        zoneBusinessRules: "Business rules",
    },
    controls: {
        deployments: {
            title: "Select deployment",
            cancel: "Cancel",
            select: "Select",
            organization: "Organization",
            department: "Department",
            deployment: "Deployment",
            deploymentsNotExist: "Not found",
        },
    },
    cameraCard: {
        code: "ID",
        zone: "Geofence",
        objectCount: "Employees in view",
        showOnMap: {
            title: "Show on map",
            button: "Show",
        },
    },
    userProfile: {
        avatarDialog: "Change photo",
        avatarDialogSave: "Save",
    },
    frames: {
        beaconSignals: {
            filter: {
                tagId: "Search by device's name, device UID",
                showInstanceId: "Show ID",
            },
            table: {
                title: "Beacon signals",
                columns: {
                    name: "Name",
                    instanceId: "ID",
                    occurred: "Signal time",
                    rssi: "RSSI, dBm",
                    distance: "Distance to the beacon, m",
                },
            },
        },
        createZone: {
            doneMessage: "Geofence was created successfully!",
        },
    },
    table: {
        selected: "Selected rows count",
        noData: "No records",
        paging: {
            info: ({ from, count, to }) => `${from}-${to}. Total ${count}`,
            rowsPerPage: "Rows per page",
            showAll: "Show all",
        },
    },
    panels: panelsEn,
    audit: {
        table: {
            columns: {
                occurred: "Datetime",
                eventType: "Event type",
                ip: "IP-address",
                initiator: "Initiator",
                objectId: "Object ID",
                browserVersion: "Browser version",
                comment: "Description",
            },
            filter: {
                name: "Name",
                placeholder: "Search",
                hintTop: "by initiator, event type",
                hintBottom: null,
                login: "Initiator",
                eventType: "Event type",
                fieldHolder: "Search in field",
                buttons: {
                    clear: "Clear filter",
                },
            },
        },
    },
    loginForm: {
        username: "Login",
        password: "Password",
        submit: "Enter",
        knownErrorHttpCodes: {
            401: "Login or password are incorrect.",
            404: "Auth service is not available. Please, try again later.",
        },
        knownErrorCodes: {
            "unknown_error": "Unknown error occurred in authentication service.",
            "invalid_grant": "Login or password are incorrect.",
        },
        helperText: "Having trouble logging in? Please, contact us",
    },
    editors: {
        ...enEditorResources,
        placement: {
            helperText: "Put a point on the map to display the model",
        },
        sensor: {
            title: "Sensor",
            edit: "Configure",
            form: {
                submit: "Save",
                cancel: "Cancel",
                zoneDialog: "Parent geofence",
                type: "Sensor type",
                typeDialog: "Sensor type",
                lostChangesDialog: {
                    title: "Are you sure you want to cancel?",
                    yesButton: "Cancel",
                    noButton: "No",
                },
                discardAllChangesDialog: {
                    title: "Are you sure you want to discard all changes?",
                    yesButton: "Discard",
                    noButton: "No",
                },
                fields: {
                    type: "Type",
                    name: "Name",
                    vendorId: "Sensor UID",
                    zoneId: "Geofence",
                    externalSystemId: "External system",
                },
            },
            deleteDialog: {
                title: "Are you sure you want to archive the sensor?",
                yesButton: "Archive",
                noButton: "Cancel",
            },
            restoreDialog: {
                title: "Are you sure you want to restore the sensor?",
                yesButton: "Restore",
                noButton: "Cancel",
            },
        },
        relay: {
            title: "Relay",
            edit: "Configure",
            form: {
                submit: "Save",
                cancel: "Cancel",
                zoneDialog: "Parent geofence",
                type: "Relay type",
                typeDialog: "Relay type",
                lostChangesDialog: {
                    title: "Are you sure you want to cancel?",
                    yesButton: "Cancel",
                    noButton: "No",
                },
                discardAllChangesDialog: {
                    title: "Are you sure you want to discard all changes?",
                    yesButton: "Discard",
                    noButton: "No",
                },
                fields: {
                    type: "Type",
                    name: "Name",
                    vendorId: "Relay UID",
                    zoneId: "Geofence",
                    externalSystemId: "External system",
                },
            },
            deleteDialog: {
                title: "Are you sure you want to archive the relay?",
                yesButton: "Archive",
                noButton: "Cancel",
            },
            restoreDialog: {
                title: "Are you sure you want to restore the relay?",
                yesButton: "Restore",
                noButton: "Cancel",
            },
        },
        camera: {
            title: "Camera",
            edit: "Configure",
            form: {
                zoneId: "Geofence",
                model: "Model",
                modelDialog: "Device 3D model",
                name: "Name",
                rtspUrl: "Main stream",
                lowResolutionRtspUrl: "Low resolution stream",
                code: "Camera ID",
                orientationBlock: "Base orientation",
                heading: "Azimuth",
                pitch: "Elevation",
                angleBlock: "View angle",
                horizontalAngle: "Horizontal",
                verticalAngle: "Vertical",
                heightAboveFloor: "Height above floor",
                cancel: "Cancel",
                submit: "Save",
                zoneDialog: "Parent geofence",
                resolution: "Resolution",
                faceRecognition: "Face recognition",
            },
            deleteCameraDialog: {
                noButton: "No",
                deleteButton: "Archive",
                title: "Are you sure you want to archive the device?",
            },
            restoreCameraDialog: {
                noButton: "No",
                restoreButton: "Restore",
                title: "Are you sure you want to restore the device?",
            },
        },
        building: {
            closeBtn: "Cancel",
            deleteLevelDialog: "Are you sure you want to delete the model file?",
            groupNameNotDefined: "New object",
            levelNameNotDefined: "Untitled level",
            parent: "Parent",
            saveBtn: "Save",
            title: "Multi-leveled object",
            addLevelBtn: "New level",
            lostChangesDialog: "Are you sure you want to cancel?",
            lostChangesDialogNo: "No",
            lostChangesDialogYes: "Cancel",
            deleteLevelButton: "Delete level",
            editLevelButton: "Change model file",
            deleteBuildingDialog: {
                noButton: "No",
                deleteButton: "Delete",
                title: "Are you sure you want to delete the object?",
            },
            zoneDialog: "Parent geofence",
        },
        buildingSelector: {
            createBtn: "Create",
            title: "Objects",
        },
        zoneSelector: {
            title: "Geofences",
            create: "Create new",
        },
        zone: {
            title: "Geofence settings",
            form: {
                name: "Name",
                height: "Height, m",
                altitude: "Altitude, m",
                parent: "Parent",
                outline: "Outline",
                createViewPoint: "Create new view point",
                viewPoint: "View Point",
                zoneBusinessRule: "Business rules",
                zoneTimeUtilization: "Category",
                cancel: "Cancel",
                submit: "Save",
                zoneDialog: "Parent geofence",
                useCurrentViewPoint: "Use current camera location",
                validation: {
                    polygon: {
                        notClosed: "The outline must be closed.",
                        notInsideParent: "All points of the outline must be within the boundaries of the parent geofence.",
                        notIntersectOthers: (zonesNames) => "The outline must not have intersections with other geofences (" +
                            zonesNames.join(" ,") +
                            ")",
                        cantContainsChildren: "All points of child geofences must be within the boundaries of the editable contour.",
                    },
                    height: {
                        notInsideParent: (min, max) => `Max height must be inside parent values. ${min} and ${max}.`,
                        invalidValue: (min, max) => `Height must be between ${min} and ${max}`,
                        cantContainsChildren: "Max height can't be lower than children max height",
                    },
                    altitude: {
                        invalidValue: (min, max) => `Altitude must be between ${min} and ${max}`,
                        notInsideParent: (min, max) => `Altitude must be inside parent values. ${min} and ${max}.`,
                        cantContainsChildren: "Altitude can't be higher than children altitude",
                    },
                },
            },
            deleteZoneDialog: {
                noButton: "No",
                deleteButton: "Delete",
                title: "Are you sure you want to delete the geofence?",
            },
            outline: {
                previousVersion: {
                    title: "Show previous version",
                    empty: "Empty",
                    copyButton: "Copy outline and height",
                },
            },
        },
        zoneModel: {
            clearZoneButton: "Delete",
            clearZoneText: "To select other geofence, unselect current",
            createZone: "Create outline",
            selectZone: "Copy outline",
            selectZoneDialog: {
                placeholder: "SELECT FROM list",
                selectButton: "Select",
                title: "Copy outline",
            },
            tabs: {
                parameters: "Parameters",
                zone: "Geofence",
            },
            createOutline: "Create Outline",
            changeOutline: "Change Outline",
            deleteGeofenceContourDialog: {
                noButton: "No",
                deleteButton: "Delete",
                title: "Are you sure you want to delete the geofence contour?",
            },
            saveOutlineTooltip: "Save outline",
            closeTooltip: "Close",
            deletePointTooltip: "Delete point",
            discardAllOutlineChangesDialog: {
                title: "Are you sure you want to discard all outline changes?",
                noButton: "No",
                discardButton: "Discard",
            },
            discardAllChangesDialog: {
                title: "Are you sure you want to discard all changes?",
                noButton: "No",
                discardButton: "Discard",
            },
            undoTooltip: "Undo",
            redoTooltip: "Redo",
            viewFromAboveTooltip: "View from above",
            secantPlaneTooltip: "Secant plane",
            createViewPoint: "Add view point",
            selectViewPoint: "Select view point",
            selectViewPointDialog: {
                selectButton: "Select view point",
                title: "View point",
            },
            viewPointNameField: "View point name",
            viewPointForm: {
                searchPlaceholder: "Search by view points",
                emptyTitle: "Select view point",
            },
            cleanViewPointDialog: {
                title: "Clean view point",
                noButton: "No",
                yesButton: "Clean",
            },
            polygonFirstPointHelper: "Put the first point.\nUsing the points of other geofences, you provide the precise coverage without gaps",
            polygonSecondPointHelper: "Put the second point",
            polygonThirdPointHelper: "Put the third point",
            polygonLastPointHelper: "Close the outline or continue to put points",
        },
        beacon: {
            form: {
                name: "Name",
                mac: "MAC address",
                txPower: "Tx power, dB",
                ibeaconUUID: "UUID",
                ibeaconMajor: "Major",
                ibeaconMinor: "Minor",
                eddystoneNamespace: "Namespace",
                eddystoneInstance: "Instance ID",
                close: "Cancel",
                submit: "Save",
                eddystoneEnabled: "",
                ibeaconEnabled: "",
            },
            lostChangesDialog: "Are you sure you want to cancel?",
            lostChangesDialogNo: "No",
            lostChangesDialogYes: "Cancel",
            deleteBeaconDialog: {
                noButton: "No",
                deleteButton: "Delete",
                title: "Are you sure you want to delete the device?",
            },
            edit: "Configure",
        },
        operations: {
            edit: "Edit",
            delete: "Delete",
            change: "Edit",
            copy: "Copy",
            archive: "Archive",
            restore: "Restore",
        },
        toolbar: {
            routeMap: "Route maps",
            objects: "Objects",
            geofences: "Geofences",
            devices: "Devices",
            locations: "Locations",
            viewPoints: "View point",
        },
        common: {
            notSelected: "Not selected",
        },
    },
    files: {
        preview: "Open preview",
        uploadButton: ["Drop files here or ", "click", ", to browse"],
        selectedFiles: "Selected files",
        selectFiles: "Add",
        deleteMessage: "Are you sure you want to delete the selected file?",
        rename: "Rename",
        download: "Download",
    },
    folders: {
        deleteMessage: "Are you sure you want to delete the folder with all its contents?",
        foldersTitle: "Folders",
        defaultFolderName: "Default",
        folderNamePlaceholder: "Enter title",
        emptyFolderName: "Untitled",
        deleteTooltip: "Delete folder",
        editTooltip: "Edit name",
        addTooltip: "New folder",
    },
    mediaGallery: {
        notSupported: "File is not supported",
    },
    empty: "Empty",
    metrics: {
        [MetricsEnum.Person]: "Person",
        [MetricsEnum.Time]: "Time",
        [MetricsEnum.Event]: "Events",
        [MetricsEnum.Device]: "Device",
        [MetricsEnum.Vehicle]: "Vehicle",
    },
    analytics: {
        reports: {
            tabs: {
                generated: "Generated",
                regular: "Regular",
                allRegular: "All regular",
            },
            ok: "The report query was successful",
            error: "Sorry, an error occurred during the operation. Try again later",
            form: {
                submit: "Generate",
                save: "Save",
                fields: {
                    email: "Enter Email",
                    date_from: "Start date",
                    date_to: "End date",
                    time_from: "Start time",
                    time_to: "End time",
                    timezone: "Timezone",
                    report_code: "Select the type of report",
                    format_code: "Format",
                    route_map: "Route map",
                    shift: "Shift",
                    is_regular: "Receive regularly",
                    generation_time: "Generation time",
                    generation_period: "Generation period",
                    delivery_channel: "Delivery channel",
                    schedule_every: "Every",
                    schedule_periodicity: "Interval",
                    schedule_start_date: "Starting from",
                    organization: "Organization",
                    department: "Department",
                    shift_cycle: "Shift cycle",
                    persons: "Persons",
                    incidentsLog: {
                        trackables: "Trackables",
                        selectTrackables: "Select person or vehicle",
                        incidentState: "Status",
                        selectIncidentState: "Select incident status",
                        incidentPriority: "Priority",
                        selectIncidentPriority: "Select incident priority",
                        incidentType: "Type",
                        selectIncidentType: "Select incident type",
                        organization: "Organization",
                        selectOrganization: "Select organization",
                        tag: "Tag",
                        selectTag: "Select tag",
                        geofence: {
                            title: "Geofence",
                            itemsSelected: "Selected",
                            noItems: "Select geofence",
                            add: "Add geofence",
                            selectGeofenceTitle: "Select geofence",
                        },
                    },
                    selectPerson: "Select a person",
                    dayOfWeek: {
                        1: "Mo",
                        2: "Tu",
                        3: "We",
                        4: "Th",
                        5: "Fr",
                        6: "Sa",
                        0: "Su",
                    },
                },
                create: "Create new",
                newReport: "New report",
                editReport: "Report parameters",
                title: "Generate report",
                validation: {
                    email_not_correct: "You entered the incorrect Email",
                    required: "Required",
                    personHasTags: "You cannot dismiss a person while they have a wearable device.",
                    personHasUser: "You cannot dismiss a person because he is a user of the system.",
                    fromDateAfterToDate: "Start date should not be greater than end date",
                    fromTimeAfterToTime: "Start time should be less than end time",
                },
                deliveryChannelNotDefined: 'It is impossible to select a delivery channel, as your profile does not contain a Phone number and an E-mail address. Generated reports will be displayed in the "Generated" list.',
                deliveryChannel: 'Generated reports will be displayed in the "Generated" list and will be delivered in the following channels: {channels}',
                multipleSubscription: {
                    default: 'Generated reports will be displayed in the "Generated" list for the selected employees',
                    noEmail: (names) => "It is impossible for these employees to deliver reports by e-mail, because their profiles do not contain an email address: " +
                        names.toString() +
                        ".",
                    noPhone: (names) => "It is impossible for these employees to deliver reports by Telegram, because their profiles do not contain a phone number: " +
                        names.toString() +
                        ".",
                    noTelegram: (names) => "It is impossible for these employees to deliver reports by Telegram, because they are not subscribed to the Telegram distribution channel: " +
                        names.toString() +
                        ".",
                },
                personalSubscription: {
                    default: 'Generated reports will be displayed in the "Generated" list.',
                    noEmail: 'It is impossible to select the "E-mail" delivery channel, because your profile does not contain an E-mail address.',
                    noPhone: 'It is impossible to select the "Telegram" delivery channel, because as your profile does not contain a Phone number.',
                    noTelegram: 'It is impossible to select the "Telegram" delivery channel, because you are not subscribed to the Telegram distribution channel.',
                },
            },
            table: {
                header: {
                    date: "Creation time",
                    type: "Type",
                    status: "Status",
                    title: "Report name",
                },
                row: {
                    status: {
                        finished: "Ready",
                        inProgress: "In progress",
                        failed: "Error",
                    },
                    title: {
                        from: "from",
                        to: "to",
                    },
                },
            },
            regular: {
                filter: {
                    hintTop: "by person,",
                    hintBottom: "report name",
                    name: "Report name",
                    recipient: "Recipient",
                },
                table: {
                    header: {
                        creationDate: "Created",
                        title: "Name",
                        formatCode: "Type",
                        deliveryChannel: "Receive by",
                        generationPeriod: "Period",
                        schedule: "Schedule",
                        scheduleGenerationTime: "Delivery time",
                        recipient: "Recipient",
                    },
                    schedule: "Every {scheduleEvery} {schedulePeriodicity},\nstarting from {scheduleStartDate}",
                },
                deleteDialog: {
                    ok: "Delete",
                    close: "Cancel",
                    title: "Are you sure you want to delete the report subscription?",
                },
                menu: {
                    editButton: "Edit",
                    deleteButton: "Delete",
                },
            },
            helper: "Loading in progress",
            download: "Download",
        },
        dashboard: {
            vehicle_connection_status: {
                name: "Connection status",
                description: "Shows the number of active (online) and inactive (offline) vehicles",
                total: "Total",
            },
            widgetFilterPresenter: {
                positions: {
                    byOne: "position",
                    byManyElement: "positions",
                },
                zoneBusinessRules: {
                    byOne: "zone business rule",
                    byManyElement: "zone business rules",
                },
                zoneTimeUtilization: {
                    byOne: "zone category",
                    byManyElement: "zone categories",
                },
                organizations: {
                    byOne: "organization",
                    byManyElement: "organizations",
                },
                persons: {
                    byOne: "person",
                    byManyElement: "persons",
                },
                more: "more",
            },
            dataRequestTime: "Status at",
            widgetAssignments: {
                [MetricsEnum.Person]: {
                    [PersonAssignmentsEnum.Zones]: "Zones",
                    [PersonAssignmentsEnum.ZoneVisits]: "Zone visits",
                    [PersonAssignmentsEnum.PositionGPN]: "Position",
                    [PersonAssignmentsEnum.ZoneCategories]: "Zone Category",
                    [PersonAssignmentsEnum.Organizations]: "Organizations",
                },
                [MetricsEnum.Time]: {
                    [TimeAssignmentsEnum.ZoneCategories]: "Zone Category",
                    [TimeAssignmentsEnum.Geofences]: "Geozones",
                },
                [MetricsEnum.Event]: {
                    [EventAssignmentsEnum.Statuses]: "Status",
                    [EventAssignmentsEnum.Persons]: "Persons",
                    [EventAssignmentsEnum.Zones]: "Zones",
                    [EventAssignmentsEnum.Priorities]: "Priority",
                    [EventAssignmentsEnum.Types]: "Type",
                },
                [MetricsEnum.Device]: {
                    [DeviceAssignmentsEnum.ConnectionStatuses]: "Connection status",
                    [DeviceAssignmentsEnum.Detector]: "Telemetry indicator",
                },
                [MetricsEnum.Vehicle]: {
                    [VehicleAssignmentsEnum.ConnectionStatuses]: "Connection status",
                },
            },
            templateDialog: {
                size: "Size",
                assignments: "Group by",
                presentation: "Preview",
                description: "Description",
                title: "Add new widget",
                selectButton: "Add new widget",
            },
            emptyTitleDashboard: "Untitled board",
            enterTitle: "Enter title",
            createBoard: "Create new board",
            selectBoard: "Select board",
            editBoard: "Configure board",
            sharedBoard: "Shared",
            changeBoardName: "Edit name",
            saveEditor: "Save board",
            closeEditor: "Cancel",
            noData: "No data",
            noDataShort: "N/D",
            emptyZoneState: "No state",
            noSignal: "No signal",
            addWidget: "Add widget",
            deleteWidgetDialog: {
                title: "Are you sure you want to remove the widget?",
                closeButton: "Cancel",
                okButton: "Delete",
            },
            deleteDashboardDialog: {
                button: "Delete board",
                title: "The selected board will be deleted. Proceed?",
            },
            period: {
                [DashboardTimeUnitEnum.TODAY]: "Today",
                [DashboardTimeUnitEnum.MONTH]: "Month",
                [DashboardTimeUnitEnum.WEEK]: "Week",
                [DashboardTimeUnitEnum.YEAR]: "Year",
                [DashboardTimeUnitEnum.YESTERDAY]: "Yesterday",
            },
            widgetForm: {
                title: "Widget configuration",
                fields: {
                    chartType: "Chart type",
                    code: "Template",
                    title: "Name",
                    positions: "Appointment",
                    department: "Department",
                    appointments: "Appointments",
                    departments: "Departments",
                    organizations: "Organizations",
                    from: "Start date",
                    persons: "Persons",
                    zoneBusinessRules: "Zone business rules",
                    zoneTimeUtilization: "Zone category",
                    zones: "Zones",
                    zone: "Zone",
                    zoneSelector: "Zone selector",
                    to: "End date",
                    incidentTypes: "Event types",
                    incidentStatuses: "Event statuses",
                    incidentPriorities: "Event priorities",
                    topCount: "Top count",
                    showNoSignal: "Show no signal",
                    pointsNum: "Points number",
                    groupingFields: "Grouping fields",
                    errors: {
                        emptyZone: "Zone selection required",
                    },
                },
                groups: {
                    common: "Common",
                    period: "Select period",
                    parameters: "Filter",
                    shift: "Shift",
                    selectShift: "Shift not selected",
                },
                buttons: {
                    cancel: "Cancel",
                    submit: "Save",
                },
            },
            legend: {
                appointments: (count) => `By ${count} appointments`,
                organizations: (count) => `By ${count} orgaizations`,
            },
            timeSpentByZoneStates: {
                name: "Time spent by zone categories",
                description: "Shows the total time spent by trackables in the zones by categories: work zone, rest zone, zone outside the territory, etc.",
            },
            timeSpentByGeofences: {
                name: "Time spent by geofences",
                description: "Shows the total time spent by employees in the selected geofence, grouped by child geofences.",
            },
            coTwoData: {
                name: "Data from chemical agent monitors",
                description: "Visualise list of sensors registered in the system and their values of CO2 measures.",
                threshold: "Threshold",
                okValue: "All values are normal",
                notOkValue: "There are values",
            },
            totalEmployeesCount: {
                name: "Number of trackables in zone",
                parentZone: "Parent zone",
                description: 'Shows the number of unique trackables that are currently in the selected "parent" zone grouped by child zones.',
                zoneStructure: "Zone structure",
                childZones: "Number of child zones:",
                noData: "There are no trackables in the selected zone",
                otherZones: (num) => "Another (" + num + ") ",
            },
            employeesAttendance: {
                name: "Zone visits",
                description: "Shows the attendance of the zone by trackables over time. Attendance - the number of unique trackables who visited the zone over the graph interval.",
            },
            tagTemperature: {
                name: "Temperature",
            },
            tagPulse: {
                name: "Heart rate",
            },
            incidentsCountByStatus: {
                name: "Events by status",
                description: "Shows the number of open and closed Events.",
                open: "Open",
                closed: "Closed",
                total: "Total",
            },
            incidentCountByZones: {
                name: "Events by zones",
                description: "Shows the number of recorded Events, by zone.",
            },
            incidentsTopByObject: {
                name: "Events by trackables",
                description: "Shows the number of recorded Events, by trackables.",
            },
            totalEmployeesInZoneForPeriod: {
                name: "Zone visits by position",
                quantityInZone: "In zone",
                quantityInOrganization: "In organization",
                description: "Shows the number of unique visits by trackables of the selected area, in comparison with the target number of employees in the organization, by position.",
            },
            employeesInZoneByOrganization: {
                name: "Number of trackables by organization",
                description: "Shows the current and target number of trackables on the site, by organization.",
                fact: "Fact",
                plan: "Plan",
            },
            connectionStatus: {
                name: "Connection status",
                description: "Shows the number of active (online) and inactive (offline) devices",
                totalDevices: "Total devices",
            },
            employeesByZoneCategories: {
                name: "Number of trackables by zone categories",
                description: "Shows the current distribution of trackables by category of zones: work zone, rest zone, zone outside the territory, etc.",
            },
        },
    },
    indicators: {
        signalLost: "Signal lost",
    },
    heatmap: {
        title: "Generate a heatmap",
        submit: "Generate",
        fields: {
            date_from: "Start date",
            date_to: "End date",
            time_from: "Start time",
            time_to: "End time",
            trackable: "Trackable person",
        },
        validation: {
            endEarlierThanStartHelpText: "The end time must be later than the start time",
        },
        change: "Change parameters",
    },
    track: {
        title: "Generate a movement route",
        route_start: "Start of a route",
        route_end: "End of route in ",
        submit: "Generate",
        fields: {
            date_from: "Start date",
            date_to: "End date",
            time_from: "Start time",
            time_to: "End time",
            trackable: "Trackable person",
        },
        validation: {
            endEarlierThanStartHelpText: "The end time must be later than the start time",
            maxPeriod: (max) => `Period must be less than ${max} hours`,
        },
        change: "Change parameters",
    },
    filterInfo: {
        from: "Start date",
        to: "End date",
        metrics: "Metrics",
        person: "Person",
        organization: "Organization",
        persons: "Persons",
        organizations: "Organizations",
        zoneVisitsByPerson: "Zone visits by person",
        zoneVisitsByOrganization: "Zone visits by organization",
        change: "Change parameters",
    },
    close: "Close",
    settings: {
        quickFilter: "Quick filter",
        quickFilterNotSelected: "Not selected",
        events: "Events",
        notifications: "Notifications",
        mapSettings: "Map settings",
        mapItems: "Map items",
        zones: "Zones",
        zoneModels: "Models",
        trackables: "Trackables",
        appearance: "Appearance",
        theme: "CustomTheme",
        cesiumShowShadows: "Show shadows",
        cesiumTerrainShadowMode: "Terrain shadow mode",
        cesiumExplicitRender: "Explicit Render",
        cesiumMaximumRenderTimeChange: "Maximum Render Time Change",
        cesiumShowFps: "Show FPS",
        cesiumShowFullScreenBtn: 'Show "Full screen" button',
        cesiumShowHomeBtn: 'Show "View Home" button',
        cesiumShowNavigationHelpBtn: 'Show "Navigation Instructions" button',
        cesiumAnisotropicFilter: "Anisotropic Filter",
        cesiumFxaa: "FXAA",
        cesiumHDR: "HDR",
        lang: "Language",
        showBuildings: "Show buildings",
        showZones: "Show zones",
        showFloors: "Show floors",
        showZoneModels: "Show models",
        showBeacons: "Show beacons",
        showSensors: "Show sensors",
        showCameras: "Show cameras",
        showTrackables: "Show trackables",
        showPersons: "Show persons",
        showProtectionEquipment: "Show protection equipments",
        showIndicators: "Show indicators",
        coordinatePicker: "Show coordinate info",
        visibilityDistance: "View distance",
        labelsVisibilityDistance: "Labels view distance",
        defaultZone: "Default zone",
        personStatPanel: "Panel in person card",
        deviceIssue: "Device issuing",
        deviceHostUrl: "Device host URL",
        deviceIssuingTimeout: "Timeout before auto submit",
        multipleDeviceIssuing: "Multiple device issuing",
        detectionRadius: "Detection radius",
        details: "Details",
        wearableDevices: "Wearable devices",
        certificates: "Certificates",
    },
    submit: "Ok",
    open: "Open",
    build: "Generate",
    time: "Time",
    loading: "Loading",
    online: "Online",
    errors: {
        error403: {
            title: "Error 403. Forbidden",
            description: "You do not have permission to access this page. Please, try again or contact with your system administrator",
        },
        runtime: "Unexpected error",
    },
    login: "Login",
    logout: {
        button: "Exit",
        dialog: {
            text: "Do you really want to logout?",
            ok: "Exit",
            cancel: "Cancel",
        },
    },
    waitingForData: "Waiting for data",
    loadingError: "Sorry, there was a problem loading the page",
    select: {
        multi: {
            counter: "Selected",
        },
        showAll: "Show all",
        hideAll: "Hide all",
        noOptionsText: "No options",
    },
    units: {
        co2: "mg/m3",
        distance: {
            meters: "m.",
        },
        fileSize: {
            0: "B",
            1: "KB",
            2: "MB",
            3: "GB",
        },
        heartRate: "bpm",
        time: {
            hour: " h",
            minute: "m",
            second: "s",
        },
        humanHour: "hh",
    },
    eventCard: {
        history: {
            columns: {
                name: "Event name",
                occurred: "Create time",
                initiator: "Initiator",
            },
        },
        messages: {
            successCopied: "Link Copied",
            copiedWithError: "Error in link copping",
            exportedWithError: "Error in card exporting",
            successExported: "Card export request sent",
        },
        toolbar: {
            closeIncident: "Close",
            openIncident: "Reopen",
            backToLog: "To log",
            report: {
                title: "New report",
                button: "Create report",
                submit: "Generate",
                buttonTitle: "Events log",
            },
            export: {
                button: "Export",
            },
        },
        comments: {
            columns: {
                text: "Comment",
                creationTime: "Create time",
                author: "Operator",
            },
            commentsForm: {
                comment: {
                    label: "Сomment text",
                    placeholder: "Type new comment",
                },
                submit: "Send",
            },
            addCommentBtn: "Add comment",
        },
        panel: {
            photo: "Photo",
            video: "Video",
            info: "Information",
            attachment: "Attachment",
            trackables: "Trackables",
            undefinedTrackables: "Undefined",
            comments: "Comments",
            media: "Media",
            cameras: "Cameras",
            snapshot: "Snapshot",
            history: "History",
        },
    },
    eventLog: {
        tags: {
            title: "Tags",
            input: "Find tag",
        },
        attachment: {
            download: "Download",
            view: "View",
        },
        columns: {
            source: "Source",
            probability: "Probability Index",
            endDateTime: "End time",
            occured: "Creation time",
            priority: "Priority",
            trackables: "Partisipant(s)",
            zone: "Geofence",
            type: "Event type",
            tags: "Tags",
            state: "Status",
            details: "Details",
            moreTip: "More",
            configDialog: {
                close: "Close",
                title: "Set columns",
            },
        },
        filter: {
            quickfilter: "Quick filter",
            priority: "Priority",
            state: "Status",
            date_from: "Start date",
            date_to: "End date",
            time_from: "Start time",
            time_to: "End time",
            choosePeriod: "Select period",
            noDataShort: "N/D",
            emptySelect: "None",
            search: {
                kinds: {
                    incidentType: "Event type",
                    zone: "Location",
                    org: "Organization",
                    tags: "Tags",
                    trackable: "Participant",
                    other: "",
                },
                placeholder: {
                    search: "Search",
                    hintTop: "participant(s), location, event type,",
                    hintBottom: "participant's organization, tag",
                },
            },
            buttons: {
                clearSelection: "Clear selection",
                clearFilter: "Clear filter",
                columns: "Set columns",
            },
        },
        history: "History",
        copyUrl: "Copy Link",
        export: "Export",
        prevEvent: "Previous Event",
        nextEvent: "Next Event",
        prevEventShort: "Prev.",
        nextEventShort: "Next.",
    },
    control: EN_CONTROL_RESOURCES,
    dayOfWeekPicker: {
        all: "all",
    },
    dateTimePicker: {
        invalidDateMessage: "Invalid format",
        okLabel: "Ok",
        cancelLabel: "Cancel",
        clearLabel: "Clear",
        timeFormat: "hh:mm A",
        dateFormat: "MM/DD/YYYY",
        ampm: true,
    },
    "locale": "en",
    "not_defined": "Undefined",
    "not_defined_plural": "Undefined",
    "zone-hierarchy": {
        "0": "Building",
        "1": "Floor",
        "2": "Room",
    },
    "snapshotCard": {
        "head": "Event information",
        "zone": "Place",
        "trackables": "Event participants",
    },
    "mapController": {
        "home": "CROC office",
        "0-level": "Complex level",
        "1-level": "Building level",
        "2-level": "Floor level",
        "3-level": "Room level",
    },
    "listEvent": {
        "fullListEvents": "View the full list of events",
        "participants": " participants",
        "noParticipants": "No participants",
    },
    "navigation": {
        map: {
            title: "Map",
            view: "Map",
            heatmap: "Heatmap",
            timeMachine: "History",
            track: "Movement route",
            snapshot: "Snapshot",
            showNorth: "Show North",
            gotoViewpoint: "Return to default point of view",
            close: "Close",
            save: "Save",
        },
        editor: "Editor",
        editors: {
            anchor: "Location placement",
            anchorForm: {
                toolbar: {
                    placement: "Placement",
                    movement: "Movement",
                    rotation: "Rotation",
                    ruler: "Ruler",
                },
                next: "Next",
                back: "Back",
                submit: "Save",
                validation: {
                    required: "Required",
                    rangeLatitude: "Only values from -90 to +90 are allowed",
                    rangeLongitude: "Only values from -180 to +180 are allowed",
                },
                selectForm: {
                    title: "Select location",
                    placeholder: "Select location",
                    create: "Create new",
                },
                changeForm: {
                    title: "Location",
                    externalSystem: "External system",
                    externalId: "External identifier",
                    name: "Name",
                    params: "Additional parameter",
                },
                rotation: {
                    title: "Rotation",
                    placeholder: "Yaw",
                },
                disposition: {
                    title: "Disposition",
                    altitude: "Altitude",
                    latitude: "Latitude",
                    longitude: "Longitude",
                    zones: "Zones",
                },
            },
            building: {
                validation: {
                    zone: "Set geofence",
                    polygon: "Set level and geofence",
                },
            },
            zoneModel: "Model placement",
            viewpoint: "Viewpoint",
            zoneModelForm: {
                next: "Next",
                back: "Back",
                submit: "Save",
                validation: {
                    required: "Required",
                    incorrectFormat: "Incorrect file format",
                    fileNotFound: "The file is not found",
                    rangeLatitude: "Only values from -90 to +90 are allowed",
                    rangeLongitude: "Only values from -180 to +180 are allowed",
                },
                selectForm: {
                    title: "Select model",
                    placeholder: "Select model",
                    create: "Create new",
                },
                changeForm: {
                    title: "Model",
                    name: "Name",
                    model: "Model file",
                    zone: "Zone",
                    parent: "Parent model",
                },
                rotation: {
                    title: "Rotation",
                    placeholder: "Yaw",
                },
                disposition: {
                    title: "Disposition",
                    altitude: "Altitude",
                    latitude: "Latitude",
                    longitude: "Longitude",
                },
                scale: {
                    title: "Scale",
                    placeholder: "Scale",
                },
            },
            devices: "Devices",
            devicesPanel: {
                installed: "installed",
                create: "Create",
                search: "Search by name",
                deviceType: {
                    beacon: "Beacons & gateways",
                    camera: "Surveillance cameras",
                    sensor: "Sensors",
                    relay: "Relay",
                },
                beaconPanel: {
                    newObjectPanelTitle: "New BLE beacon",
                    editObjectPanelTitle: "BLE beacon",
                },
                cameraPanel: {
                    newObjectPanelTitle: "New camera",
                    editObjectPanelTitle: "Camera",
                },
                sensorPanel: {
                    newObjectPanelTitle: "New sensor",
                    editObjectPanelTitle: "Sensor",
                },
                relayPanel: {
                    newObjectPanelTitle: "New relay",
                    editObjectPanelTitle: "Relay",
                },
            },
        },
        userProfile: "Employee profile",
        eventCard: "Event card",
        "eventLog": "Events",
        analyticsPanel: "Analytics and reports",
        "admin": "Control",
        "analytics": "Dashboard",
        "reports": "Reports",
        "businessRulesConstructor": "Business Rules 2.0",
        "businessRules": "Business Rules",
        layers: "Layers",
        data: "Data",
        control: "Catalogs",
        "selector": {
            "placeholder": "Value is not set",
            "groups": {
                "zones": "All zones",
                "trackables": "All workers",
                "viewPoints": "All viewpoints",
                organizations: "All organizations",
                cameras: "All cameras",
            },
        },
        breadCrumbs: {
            selectNext: "Select object/zone",
            selectZone: "Select zone",
            search: "Search by zones",
        },
        audit: "Security Audit Log",
    },
    "model": {
        "Zone": {
            "title": "Title",
        },
        "Building": {
            "address": "Address",
            "floorsCount": "floors",
        },
        beacon: "Beacon",
        sensor: "Sensor",
    },
    layers: {
        panel: {
            title: "Map layer",
            tabs: {
                base: "Base",
                additional: "Additional",
            },
        },
        name: "Name",
        source: "Source",
        enable: "Enable",
        createDate: "Upload date",
        version: "Version",
        show: "Show in selector",
        url: "URL",
        files: "Files",
        untitled: "Untitled",
        type: {
            title: "Layer type",
            base: "Base",
            additional: "Additional",
        },
        editor: {
            title: "Layers",
            external: "External",
            local: "Local",
            addLayer: "Add new",
            addVersion: "Add version",
            editButton: "Edit",
            removeButton: "Remove",
            newLayer: "New layer",
            editLayer: "Layer properties",
            deleteMessage: "Are you sure you want to delete the selected layer?",
        },
        form: {
            add: "Add",
            update: "Save",
        },
    },
    validation: {
        required: "Field must not be empty",
        mustBeLink: "Field must contains a link",
        mustBeEmail: "Field must contains a correct email",
        mustBePhoneNumber: "Field must contains a correct phone number",
        mustBeColorCode: "Field must contains a correct color HEX-code (#FFF or #FFFFFF)",
        invalidCharacters: "The field contains invalid characters",
    },
    "zone-common-info": {
        "title": "Basic information",
        "editor": "Edit",
    },
    "zoneCommonInfo": {
        "trackables": {
            "title": "Employees",
        },
    },
    "viewpointCreator": {
        "title": "Create new viewpoint?",
        "viewpointNameInputLabel": "Name",
        "confirmationButtonContent": "Yes",
        "cancelButtonContent": "No",
    },
    timeMachineController: {
        done: "Done",
        title: "Select the time period",
        startTime: "Start time",
        endTime: "End time",
        date_from: "Start date",
        date_to: "End date",
        time_from: "Start time",
        time_to: "End time",
        playButtomHint: "Play",
        pauseButtonHint: "Pause",
        stopButtonHint: "Stop",
        notFilledValueHelpText: "Please enter a value",
        endEarlierThanStartHelpText: "The end time must be later than the start time",
        endLaterThatCurrentTimeHelpText: "The end time must be earlier than the current time",
        startLaterThatCurrentTimeHelpText: "The start time must be earlier than the current time",
        emptyPeriodHelpText: "No information for the selected period",
        speed: "Speed",
        currentTime: "Current time",
    },
    "tag-card": {
        temperature: {
            title: "Air temperature",
            units: "°C",
        },
        pulse: {
            title: "Heart rate",
            units: "BPM",
        },
    },
    organizationCard: {
        inWorkingAreas: "In working areas",
        atTheSite: "At the site",
        allPersons: "Total",
        persons: "Employees",
        departments: "Departments",
        contacts: {
            title: "Contacts",
            address: "Address",
            email: "Mail",
            website: "Website",
            phone: "Phone",
        },
        color: {
            title: "Color indicator",
        },
    },
    "person-card": {
        certificates: "Certificates",
        healthStatus: "Health status",
        heartRate: "Heart rate",
        openProfile: "Open profile",
        showOnMap: {
            title: "Show on map",
            button: "Show",
        },
        devices: {
            title: "Devices",
            chargeLevel: "Charge",
            signal: "Signal",
            used: "Yes",
            notUsed: "No",
        },
        objectTracking: "Object tracking",
        protectionEquipment: "Protection equipment",
    },
    "person-card.title": "Employee",
    "person-card.tel": "Phone",
    "trackable-card.title": "Trackable object",
    certificateCard: {
        notValid: "Not actual",
        validWithWarning: "Actual for less than 3 months",
        valid: "Actual",
        issuedBy: "Issued by",
        till: "till",
    },
    workPermitCard: {
        infoTitle: "Information",
        description: "Description",
        registrationDate: "Reg. date",
        registrationNumber: "Reg. num",
        createdAt: "Created",
    },
    "zone-common-info.editor": "Editor",
    organizations: {
        panel: {
            title: "Organizations",
        },
        declension: {
            nominative: (number) => declOfNum(number, ["Organization", "Organizations", "Organizations"]),
            genitive: (number) => declOfNum(number, ["Organization", "Organizations", "Organizations"]),
        },
    },
    employees: {
        declension: {
            nominative: (number) => declOfNum(number, ["Employee", "Employees", "Employees"]),
            genitive: (number) => declOfNum(number, ["Employee", "Employees", "Employees"]),
        },
    },
    zones: {
        declension: {
            nominative: (number) => declOfNum(number, ["Zone", "Zones", "Zones"]),
            genitive: (number) => declOfNum(number, ["Zone", "Zones", "Zones"]),
        },
    },
    appointments: {
        declension: {
            nominative: (number) => declOfNum(number, ["appointment", "appointments", "appointments"]),
            genitive: (number) => declOfNum(number, ["appointment", "appointments", "appointments"]),
        },
    },
    deployments: {
        declension: {
            nominative: (number) => declOfNum(number, ["deployment", "deployments", "deployments"]),
            genitive: (number) => declOfNum(number, ["deployment", "deployments", "deployments"]),
        },
    },
    "incidents": {
        "statusbar": {
            "alert": "Alert",
            "attention": "Attention",
        },
        "panel": {
            media: "Media",
            persons: "Employees",
            cameras: "Cameras",
            events: "Events",
            eventType: "Priority",
            status: "Status",
            openingDate: "Creation time",
            location: "Geofence",
            showMore: "Details",
            mapSnapshot: "Map snapshot",
            show: "Show",
            history: "History",
        },
    },
    formatters: {
        position: {
            latitude: "N",
            longitude: "W",
            altitude: "height",
            altitudeUnitMeasure: "m",
        },
        meters: "m",
    },
    noOrganization: "Without organization",
    noAppointment: "Without appointment",
    enum: enEnumResources,
    pretext: {
        by: "by",
        and: "and",
    },
    colors: {
        "#18A4E1": "Blue",
        "#52AEA3": "Tradewind",
        "#017F8D": "Blue Lagoon",
        "#98531C": "Rope",
        "#A6BF30": "Atlantis",
        "#FEBD85": "Macaroni and Cheese",
        "#FC6971": "Wild Watermelon",
        "#F1A7E4": "Chantilly",
        "#3DB072": "Ocean Green",
        "#D86248": "Red Damask",
        "#C18C85": "Old Rose",
        "#6A5BCA": "Blue Marguerite",
    },
};
export default enResources;
