import { lazy, memo, Suspense, useEffect, useMemo } from "react";
import { startUrl, appTitle, faviconUrl } from "@dwclient/config";
import CustomThemeProvider, { defaultTheme } from "./themes/CustomThemeProvider";
import BatchRequestContext, { RequestHandler } from "./contexts/BatchRequestContext";
import TimeZoneWorker from "./workers/timezone/TimeZoneWorker";
import TimeZoneWorkerContext from "./contexts/TimeZoneWorkerContext";
import { Loader } from "@croc.drpo_rnd/ui-kit";
import resources from "@dwclient/resources";
import { Route, Router, Switch } from "react-router-dom";
import withErrorHandling from "./components/utils/withErrorHandling";
import { createBrowserHistory } from "history";
import { hot } from "react-hot-loader/root";
import { Helmet } from "react-helmet";

const MultiWindowApp = lazy(() => import("./app/MultiWindowApp"));
const SingleWindowApp = lazy(() => import("./app/SingleWindowApp"));

const defaultPageTitle = appTitle || "Digital Worker";
const iconUrl = faviconUrl || `${process.env.PUBLIC_URL}/img`;

const metaData = (
    <Helmet>
        <title>{defaultPageTitle}</title>
        <link rel="apple-touch-icon" sizes="57x57" href={`${iconUrl}/apple-icon-57x57.png`} />
        <link rel="apple-touch-icon" sizes="60x60" href={`${iconUrl}/apple-icon-60x60.png`} />
        <link rel="apple-touch-icon" sizes="72x72" href={`${iconUrl}/apple-icon-72x72.png`} />
        <link rel="apple-touch-icon" sizes="76x76" href={`${iconUrl}/apple-icon-76x76.png`} />
        <link rel="apple-touch-icon" sizes="114x114" href={`${iconUrl}/apple-icon-114x114.png`} />
        <link rel="apple-touch-icon" sizes="120x120" href={`${iconUrl}/apple-icon-120x120.png`} />
        <link rel="apple-touch-icon" sizes="144x144" href={`${iconUrl}/apple-icon-144x144.png`} />
        <link rel="apple-touch-icon" sizes="152x152" href={`${iconUrl}/apple-icon-152x152.png`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`${iconUrl}/apple-icon-180x180.png`} />
        <link rel="apple-touch-icon" sizes="256x256" href={`${iconUrl}/icon-256.png`} />
        <link rel="apple-touch-icon" sizes="512x512" href={`${iconUrl}/icon-512.png`} />
        <link rel="apple-touch-icon" sizes="1024x1024" href={`${iconUrl}/icon-1024.png`} />
        <link rel="icon" type="image/png" sizes="192x192" href={`${iconUrl}/android-icon-192x192.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`${iconUrl}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="96x96" href={`${iconUrl}/favicon-96x96.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`${iconUrl}/favicon-16x16.png`} />
        <meta name="msapplication-TileImage" content={`${iconUrl}/ms-icon-144x144.png`} />
    </Helmet>
);

const AppLoader = withErrorHandling(() => (
    <Switch>
        <Route exact path="/window/:number" component={MultiWindowApp} />
        <Route path="/" component={SingleWindowApp} />
    </Switch>
));

function App() {
    const requestHandler = useMemo(() => new RequestHandler(), []);
    const timeZoneWorker = useMemo(() => new TimeZoneWorker(5000), []);

    console.debug("App started");

    useEffect(() => {
        return () => {
            requestHandler.close();
            timeZoneWorker.terminate();
        };
    }, [requestHandler, timeZoneWorker]);

    const history = useMemo(
        () =>
            createBrowserHistory({
                basename: startUrl,
            }),
        []
    );

    useEffect(() => {
        console.debug("Initialized history");
        return history.listen((state, action) => console.debug("History", state, action));
    }, [history]);

    return (
        <TimeZoneWorkerContext.Provider value={timeZoneWorker}>
            <BatchRequestContext.Provider value={requestHandler}>
                <CustomThemeProvider theme={defaultTheme}>
                    {metaData}
                    <Router history={history}>
                        <Suspense fallback={<Loader text={resources.loading} />}>
                            <AppLoader />
                        </Suspense>
                    </Router>
                </CustomThemeProvider>
            </BatchRequestContext.Provider>
        </TimeZoneWorkerContext.Provider>
    );
}

export default hot(memo(App));
