import EditableZone from "./EditableZone";
import EditableZoneModel from "./EditableZoneModel";
import Coords from "./Coords";
class EditableBuilding extends EditableZone {
    constructor({ floors, zoneModel, ...args } = {}, viewPoints) {
        super(args, viewPoints);
        this.angle = 0;
        this.scale = 1;
        this.selectedFloor = -1;
        this.formTab = 0;
        this.oldAngle = 0;
        this.oldScale = 1;
        this.floors = floors
            ? floors.map((value, idx) => {
                const ezm = new EditableZoneModel(value, viewPoints, args.zoneTimeUtilization);
                ezm.oldIdx = idx;
                return ezm;
            })
            : [];
        this.zoneModel = zoneModel && new EditableZoneModel(zoneModel, viewPoints, args.zoneTimeUtilization);
        this.position = this.oldPosition =
            this.floors.length > 0
                ? this.floors[0].position
                : new Coords({
                    altitude: null,
                    longitude: null,
                    latitude: null,
                });
        this.angle = this.oldAngle = (this.floors.length > 0 && this.floors[0].angle) || 0;
        this.scale = this.oldScale = (this.floors.length > 0 && this.floors[0].scale) || 1;
        this.selectedFloor = args.selectedFloor;
        this.formTab = args.formTab;
    }
    static prepareToSave(before, after) {
        if (!before) {
            return after;
        }
        return {
            ...EditableZone.prepareToSave(before, after),
            floors: before.floors
                ? after.floors.map((value) => ({
                    ...value,
                    zone: EditableZone.prepareToSave(before.floors.find((floor) => floor.zone.id === value.zone.id)?.zone, value.zone),
                }))
                : after.floors,
        };
    }
}
export default EditableBuilding;
