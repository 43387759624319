export default class LocalLayer {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.workspace = data.workspace;
        this.title = data.title;
        this.base = data.base;
        this.show = data.show;
        this.createdDate = new Date(data.createdDate);
        this.paths = data.paths;
        this.boundingBox = data.boundingBox;
    }
}
