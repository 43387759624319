class ExternalSystem {
    constructor(externalSystem) {
        if (!externalSystem.id) {
            throw new Error("Id is not defined");
        }
        this.id = externalSystem.id;
        this.sourceSystemId = externalSystem.sourceSystemId;
        this.code = externalSystem.code;
        this.title = externalSystem.title;
        this.description = externalSystem.description;
        this.tagTypes = externalSystem.tagTypes || [];
    }
}
ExternalSystem.typeName = "ExternalSystem";
export default ExternalSystem;
