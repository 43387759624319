export default class LayerDatasourceAttribute {
    constructor(data) {
        this.id = data.id;
        this.ts = data.ts;
        this.code = data.code;
        this.type = data.type;
        this.value = data.value;
        this.datasourceId = data.datasourceId;
    }
}
