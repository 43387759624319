import Trackable from "./Trackable";
class Vehicle extends Trackable {
    constructor(vehicle, baseUrl) {
        super(vehicle, baseUrl);
        this.type = Vehicle.typeName;
        this.inventoryNumber = vehicle.inventoryNumber;
        this.registrationPlate = vehicle.registrationPlate;
        this.externalId = vehicle.externalId;
        this.departmentId = vehicle.departmentId;
        this.organizationId = vehicle.organizationId;
        this.syncEnabled = vehicle.syncEnabled;
        this.status = vehicle.status;
        this.vehicleType = vehicle.vehicleType;
    }
}
Vehicle.typeName = "Vehicle";
export default Vehicle;
