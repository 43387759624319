import BaseEditableObject from "./BaseEditableObject";
import { CameraViewEnum } from "../enums";
class EditableCamera extends BaseEditableObject {
    constructor({ model, installDirection, ...args } = {}) {
        super(args);
        this.name = args.name;
        this.code = args.code;
        this.rtspUrl = args.rtspUrl;
        this.lowResolutionRtspUrl = args.lowResolutionRtspUrl;
        this.position = args.position;
        this.zoneId = args.zoneId;
        this.viewAngleHorizontal = args.viewAngleHorizontal;
        this.viewAngleVertical = args.viewAngleVertical;
        this.zoom = args.zoom;
        this.onvifApiUrl = args.onvifApiUrl;
        this.resolutionId = args.resolutionId;
        this.codecId = args.codecId;
        this.fps = args.fps;
        this.heightAboveFloor = args.heightAboveFloor;
        this.installDirection = installDirection || {
            x: 0,
            y: 0,
            z: 0,
        };
        this.model = model || CameraViewEnum.Box;
        this.faceRecognition = args.faceRecognition;
        this.status = args.status;
    }
}
export default EditableCamera;
