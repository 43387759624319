import Trackable from "./Trackable";
import { Gender } from "@croc.drpo_rnd/backend-api";
class Person extends Trackable {
    constructor(person, baseUrl) {
        super(person, baseUrl);
        this.type = Person.typeName;
        this.email = person.email;
        this.department = person.department;
        this.phone = person.phone;
        this.photo = person.photoUrl;
        this.organization = person.organization;
        this.deploymentId = person.deploymentId;
        this.deploymentDepartment = person.deploymentDepartment;
        this.deploymentOrganization = person.deploymentOrganization;
        this.firstName = person.firstName;
        this.lastName = person.lastName;
        this.middleName = person.middleName;
        this.syncEnabled = person.syncEnabled;
        this.userId = person.userId;
        this.hasTelegram = person.hasTelegram;
        this.status = person.status;
        this.externalId = person.externalId;
        this.gender = person.gender ?? Gender.NOT_SPECIFIED;
        this.birthDate = person.birthDate;
        this.lastHealthCheck = person.lastHealthCheck;
        this.schedule = person.schedule;
    }
}
Person.typeName = "Person";
export default Person;
