const ruEditorResources = {
    routeMaps: {
        title: "Карты обхода",
        create: "Создать карту обхода",
        newTitle: "Настройки карты обхода",
        deleteDialog: {
            noButton: "Закрыть",
            deleteButton: "Удалить",
            title: "Вы уверены что хотите удалить карту обхода?",
        },
        calendarPickAll: "все",
        forms: {
            mapForm: {
                deployments: "Должности",
                active: "Исполнять",
                name: "Наименование",
                calendar: "Исполнять по дням",
                interval: "Исполнять с интервалом",
                shiftCycle: "График смен",
            },
            pointForm: {
                delete: "Удалить точку обхода",
                active: "Исполнять",
                notActive: "Не исполнять",
                inspectionFrequency: "Частота посещения",
                periodicity: "Период",
                inspectionsNumber: "Раз",
                tabs: {
                    parameters: "Параметры",
                    equipments: "Оборудование",
                },
                shifts: "Смены",
                period: "Время осмотра",
                endTime: "По",
                startTime: "С",
                calendar: "Календарь",
                equipments: {
                    name: "Наименование",
                    inspectorsNumber: "Количество обходчиков",
                },
            },
            deployments: {
                add: "Добавить",
                delete: "Удалить",
            },
            points: {
                add: "Добавить точку обхода",
                copy: "Скопировать точку обхода",
                delete: "Удалить",
            },
        },
        messages: {
            emptyMapName: "Новая карта",
            emptyShiftCycle: "Не выбран график смен",
            emptyDeploymentsError: "Необходимо выбрать должности",
            emptyEquipmentError: "Необходимо указать оборудование",
            emptyPointName: "Оборудование не выбрано",
            emptyPointsError: "Нет точек обхода",
            deletePointDialog: {
                close: "Закрыть",
                ok: "Удалить",
                title: "Вы уверены что хотите удалить точку обхода?",
            },
            selectRoutePointDialog: {
                title: "Выберите точку обхода",
                routeMap: "Маршрутная карта",
                routePoint: "Точка обхода",
                useAllPoints: "Выбрать все точки обхода",
                cancel: "Отмена",
                select: "Выбрать",
            },
        },
    },
};
export default ruEditorResources;
