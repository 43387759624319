import WorkerMessage from "../WorkerMessage";

export type Value = {
    text: string;
};

class SearchTimezoneWorkerMessage extends WorkerMessage<"GET_TIMEZONE_BY_TEXT", Value> {
    constructor(text: string | null, callerId: string) {
        super("GET_TIMEZONE_BY_TEXT", callerId, {
            text,
        });
    }
}

export default SearchTimezoneWorkerMessage;
