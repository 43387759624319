import Incident from "./Incident";
export default class IncidentsStatus {
    constructor(incidentsStatus) {
        this.alertCount = incidentsStatus.alertCount;
        this.attentionCount = incidentsStatus.attentionCount;
        this.infoCount = incidentsStatus.infoCount;
        this.lastAlertIncidents = incidentsStatus.lastAlertIncidents.map((value) => new Incident(value));
        this.lastAttentionIncidents = incidentsStatus.lastAttentionIncidents.map((value) => new Incident(value));
        this.lastInfoIncidents = incidentsStatus.lastInfoIncidents.map((value) => new Incident(value));
        this.ts = incidentsStatus.ts || Date.now();
    }
}
