abstract class WorkerMessage<COMMAND extends string = string, T = any> {
    protected constructor(
        readonly command: COMMAND,
        readonly caller: string,
        readonly data: T,
        readonly error: string = null
    ) {
        if (error) {
            this.data = null;
        }
    }
}

export default WorkerMessage;
