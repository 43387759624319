import DWFile from "./DWFile";
import { buildFileLink } from "../utils/fileUtils";
export default class DWModelFile extends DWFile {
    constructor(url, baseUrl) {
        if (typeof url === "string") {
            const fileName = url.replace(/^(.*\/)+/, "");
            super({
                name: fileName,
                createdAt: null,
                lastModifiedAt: null,
                path: url,
                contentType: "model/gltf-binary",
                size: 0,
            }, buildFileLink(url, baseUrl));
        }
        else {
            super(url, buildFileLink(url.path, baseUrl));
        }
    }
}
