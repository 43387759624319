export default class Contacts {
    constructor(contact) {
        if (!contact) {
            return;
        }
        this.phone = contact.phone;
        this.address = contact.address;
        this.email = contact.email;
        this.website = contact.website;
    }
}
