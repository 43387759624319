import * as uuid from "uuid";
import axios from "axios";
const { CancelToken } = axios;
class UploadedFile {
    constructor(file, dir) {
        this.percent = 0;
        this.id = uuid.v4();
        this.file = file;
        this.dir = dir;
        this.name = file.name;
        this.size = file.size;
        this.created = new Date();
        this.cancelSource = CancelToken.source();
    }
}
export default UploadedFile;
