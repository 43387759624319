class BusinessRuleConfig {
    constructor() {
        this.isNew = true;
    }
    static createFromBusinessRuleConfig(anotherBusinessRuleConfig) {
        const businessRuleConfig = new BusinessRuleConfig();
        businessRuleConfig.id = anotherBusinessRuleConfig.id;
        businessRuleConfig.name = anotherBusinessRuleConfig.name;
        businessRuleConfig.status = anotherBusinessRuleConfig.status;
        businessRuleConfig.businessRule = anotherBusinessRuleConfig.businessRule;
        businessRuleConfig.geofencesExclusion = anotherBusinessRuleConfig.geofencesExclusion;
        businessRuleConfig.geofenceIds = anotherBusinessRuleConfig.geofenceIds;
        businessRuleConfig.personsExclusion = anotherBusinessRuleConfig.personsExclusion;
        businessRuleConfig.personIds = anotherBusinessRuleConfig.personIds;
        businessRuleConfig.positionsExclusion = anotherBusinessRuleConfig.positionsExclusion;
        businessRuleConfig.positionIds = anotherBusinessRuleConfig.positionIds;
        businessRuleConfig.createdAt = anotherBusinessRuleConfig.createdAt;
        businessRuleConfig.createdBy = anotherBusinessRuleConfig.createdBy;
        businessRuleConfig.lastModifiedAt = anotherBusinessRuleConfig.lastModifiedAt;
        businessRuleConfig.lastModifiedBy = anotherBusinessRuleConfig.lastModifiedBy;
        businessRuleConfig.ts = anotherBusinessRuleConfig.ts;
        businessRuleConfig.isNew = anotherBusinessRuleConfig.isNew;
        return businessRuleConfig;
    }
}
export default BusinessRuleConfig;
