/**
 * Dto "Канал отправки сообщений во внешнюю систему"
 */
class ExternalSystemMessageChannel {
    constructor(id, name, type, externalSystemId) {
        this.id = id;
        this.name = name;
        this.externalSystemId = externalSystemId;
        this.type = type;
    }
}
export default ExternalSystemMessageChannel;
