import VersionedEditableObject from "./VersionedEditableObject";
class EditableSensor extends VersionedEditableObject {
    constructor(args) {
        super(args);
        this.type = args.type;
        this.name = args.name;
        this.vendorId = args.vendorId;
        this.externalSystemId = args.externalSystemId;
        this.zoneId = args.zoneId;
        this.position = args.position;
        this.status = args.status;
    }
}
export default EditableSensor;
