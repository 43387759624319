import Versioned from "./Versioned";
import Equipment from "./Equipment";
export default class RoutePoint extends Versioned {
    constructor(data) {
        super(data);
        this.active = data.active;
        this.equipment = new Equipment(data.equipment);
        this.orderNumber = data.orderNumber;
        this.inspectionsNumber = data.inspectionsNumber;
        this.periodicity = data.periodicity;
        this.shiftIds = data.shiftIds;
        this.startTime = data.startTime && new Date(data.startTime);
        this.endTime = data.endTime && new Date(data.endTime);
    }
}
