/**
 * Тип машины
 */
import VehicleStatusEnum from "../../enums/VehicleStatusEnum";
class VehicleType {
    constructor() {
        this.isNew = true;
        this.status = VehicleStatusEnum.ACTIVE;
    }
}
export default VehicleType;
