import { CameraViewEnum, Domain, IncidentGroupingFieldEnum, PlaybackSpeedEnum } from "@dwclient/domain";
const enEnumResources = {
    InspectionPeriodicity: {
        [Domain.InspectionPeriodicity.MONTH]: "Month",
        [Domain.InspectionPeriodicity.WEEK]: "Week",
        [Domain.InspectionPeriodicity.DAY]: "Day",
        [Domain.InspectionPeriodicity.SHIFT]: "Work shift",
    },
    DeviceStatus: {
        [Domain.DeviceStatus.FAULTY]: "Faulty",
        [Domain.DeviceStatus.FREE]: "Free",
        [Domain.DeviceStatus.ISSUED]: "Issued",
        [Domain.DeviceStatus.LOST]: "Lost",
        [Domain.DeviceStatus.UNIDENTIFIED]: "Unidentified",
        [Domain.DeviceStatus.ARCHIVED]: "Archived",
    },
    names: {
        cameraView: "Pre-configured model",
        sensorType: "Sensor type",
    },
    shortGenerationPeriod: {
        [Domain.ReportGenerationPeriod.THIS_DAY]: "Today",
        [Domain.ReportGenerationPeriod.PREVIOUS_DAY]: "Yesterday",
        [Domain.ReportGenerationPeriod.THIS_WEEK]: "This week",
        [Domain.ReportGenerationPeriod.PREVIOUS_WEEK]: "Previous week",
        [Domain.ReportGenerationPeriod.THIS_MONTH]: "This month",
        [Domain.ReportGenerationPeriod.PREVIOUS_MONTH]: "Last month",
    },
    auditComment: {
        [Domain.AuditEventType.ARCHIVE_USER]: "User changed: <login>",
        [Domain.AuditEventType.CREATE_NEW_USER]: "User created: <login>",
        [Domain.AuditEventType.INCIDENT_CARD_VIEW]: "",
        [Domain.AuditEventType.TAG_ADDED]: "Added tag <tag>",
        [Domain.AuditEventType.TAG_DELETED]: "Deleted tag <tag>",
        [Domain.AuditEventType.EVENT_STATUS_CHANGED]: "The event is transferred to the <status> status",
        [Domain.AuditEventType.COMMENT_ADDED]: "Added comment",
        [Domain.AuditEventType.LOGIN]: "",
        [Domain.AuditEventType.LOGIN_FAILED]: "",
        [Domain.AuditEventType.LOGOUT]: "",
        [Domain.AuditEventType.RESTORE_USER]: "User changed: <login>",
        [Domain.AuditEventType.USER_PASSWORD_CHANGED]: "User changed: <login>",
        [Domain.AuditEventType.USER_ROLE_CHANGED]: "User changed: <login>\nAdded roles: <addedRoleNames>\nDeleted roles: <removedRoleNames>",
        [Domain.AuditEventType.USER_TO_PERSON_LINK_CHANGE]: "User changed: <login>\nOld link: <oldPerson.lastName> <oldPerson.firstName> <oldPerson.middleName>(<oldPerson.id>)\nNew link: <newPerson.lastName> <newPerson.firstName> <newPerson.middleName>(<newPerson.id>)",
        [Domain.AuditEventType.FILE_ADDED]: "Added file <file>",
        [Domain.AuditEventType.FILE_DELETED]: "Deleted file <file>",
        [Domain.AuditEventType.MESSAGE_SENT_BUSINESS]: 'Message with text "<message>" sent to employee <addresseeFio>',
        [Domain.AuditEventType.MESSAGE_SENT_OPERATOR]: 'Message with text "<message>" sent to employee <addresseeFio>',
        [Domain.AuditEventType.OBJECT_CREATED]: "Object created: <object>",
        [Domain.AuditEventType.OBJECT_UPDATED]: "Object changed: <object>",
    },
    AuditEventType: {
        [Domain.AuditEventType.ARCHIVE_USER]: "Archive user",
        [Domain.AuditEventType.CREATE_NEW_USER]: "Create new user",
        [Domain.AuditEventType.INCIDENT_CARD_VIEW]: "View event",
        [Domain.AuditEventType.TAG_ADDED]: "Add tag to event",
        [Domain.AuditEventType.TAG_DELETED]: "Delete tag from event",
        [Domain.AuditEventType.EVENT_STATUS_CHANGED]: "Changing status in event",
        [Domain.AuditEventType.COMMENT_ADDED]: "Added comment",
        [Domain.AuditEventType.LOGIN]: "Login",
        [Domain.AuditEventType.LOGIN_FAILED]: "Entering an incorrect username / password",
        [Domain.AuditEventType.LOGOUT]: "Logout",
        [Domain.AuditEventType.RESTORE_USER]: "Restore user",
        [Domain.AuditEventType.USER_PASSWORD_CHANGED]: "Change user password",
        [Domain.AuditEventType.USER_ROLE_CHANGED]: "Change user role",
        [Domain.AuditEventType.USER_TO_PERSON_LINK_CHANGE]: "Link / unlink a person",
        [Domain.AuditEventType.FILE_ADDED]: "Added file",
        [Domain.AuditEventType.FILE_DELETED]: "Deleted file",
        [Domain.AuditEventType.MESSAGE_SENT_BUSINESS]: "Notifications sent automatically",
        [Domain.AuditEventType.MESSAGE_SENT_OPERATOR]: "Notifications sent by operator",
        [Domain.AuditEventType.OBJECT_CREATED]: "Create new object",
        [Domain.AuditEventType.OBJECT_UPDATED]: "Edit object",
    },
    deliveryChannel: {
        [Domain.DeliveryChannel.TELEGRAM]: "Telegram",
        [Domain.DeliveryChannel.EMAIL]: "E-mail",
    },
    reportSchedulePeriodicity: {
        [Domain.ReportSchedulePeriodicity.DAY]: "Day",
        [Domain.ReportSchedulePeriodicity.WEEK]: "Week",
        [Domain.ReportSchedulePeriodicity.MONTH]: "Month",
    },
    "incidentPriority": {
        all: "All",
        [Domain.IncidentPriority.INFO]: "Info",
        [Domain.IncidentPriority.ATTENTION]: "Attention",
        [Domain.IncidentPriority.ALERT]: "Alert",
    },
    "healthStatus": {
        [Domain.HealthStatus.GOOD]: "Good",
        [Domain.HealthStatus.WARNING]: "Warning",
        [Domain.HealthStatus.CRITICAL]: "Critical",
        [Domain.HealthStatus.FATAL]: "Fatal",
    },
    "cesium": {
        ShadowMode: {
            1: "The object casts and receives shadows",
            0: "The object does not cast or receive shadows",
            3: "The object receives shadows only",
            2: "The object casts shadows only",
        },
    },
    groupingType: {
        [IncidentGroupingFieldEnum.PRIORITY]: "Incident priority",
        [IncidentGroupingFieldEnum.STATUS]: "Incident status",
        [IncidentGroupingFieldEnum.INCIDENT_TYPE]: "Incident type",
    },
    infoTypeCodes: {
        TEMPERATURE: "Temperature",
        PULSE: "Heart rate",
    },
    connectionStatus: {
        [Domain.ConnectionStatus.ONLINE]: "Online",
        [Domain.ConnectionStatus.OFFLINE]: "Offline",
    },
    errorResponseCode: {
        [Domain.ErrorResponseCode.UNKNOWN]: "An unexpected error occured",
        [Domain.ErrorResponseCode.FORMATTING_ERROR]: "An unexpected error occured",
        [Domain.ErrorResponseCode.TIMEOUT]: "Server response timed out",
        [Domain.ErrorResponseCode.VALIDATION_ERROR]: "Errors in filling the fields",
        [Domain.ErrorResponseCode.NOT_IMPLEMENTED]: "An unexpected error occured",
        [Domain.ErrorResponseCode.CLICKHOUSE_ERROR]: "An unexpected error occured",
        [Domain.ErrorResponseCode.DATABASE_ERROR]: "An unexpected error occured",
        [Domain.ErrorResponseCode.PARSING_ERROR]: "An unexpected error occured",
    },
    cameraView: {
        [CameraViewEnum.Box]: "Box camera",
        [CameraViewEnum.Dome]: "Dome camera",
    },
    SensorType: {
        [Domain.SensorType.CO2]: "Gas analyzer",
        [Domain.SensorType.RFID_READER]: "Badge reader",
        [Domain.SensorType.CONTROLLED_RELAY]: "Controlled relay",
    },
    playbackSpeed: {
        [PlaybackSpeedEnum.x1]: "x1 (normal)",
        [PlaybackSpeedEnum.x2]: "x2",
        [PlaybackSpeedEnum.x8]: "x8",
        [PlaybackSpeedEnum.x32]: "x32",
        [PlaybackSpeedEnum.x100]: "x100",
        [PlaybackSpeedEnum.x500]: "x500",
        [PlaybackSpeedEnum.x1000]: "x1000",
    },
    gender: {
        [Domain.Gender.NOT_SPECIFIED]: "Not specified",
        [Domain.Gender.MALE]: "Male",
        [Domain.Gender.FEMALE]: "Female",
    },
};
export default enEnumResources;
