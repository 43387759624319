import ModelInfo from "./ModelInfo";
class TrackableGroup {
    constructor(data, baseUrl) {
        this.id = data.id;
        this.ts = data.ts;
        this.name = data.name;
        this.model = data.model && new ModelInfo(data.model, baseUrl);
    }
}
export default TrackableGroup;
