import Person from "./Person";
import Coords from "./Coords";
export default class IncidentData {
    constructor(data) {
        if (!data.id) {
            throw new Error("Id is not defined");
        }
        this.id = data.id;
        this.num = data.num;
        this.occured = data.occured && new Date(data.occured);
        this.typeName = data.typeName;
        this.state = data.state;
        this.priority = data.priority;
        this.location = data.place;
        this.position = data.position && new Coords(data.position);
        this.endDatetime = data.endDatetime && new Date(data.endDatetime);
        this.trackables = data.trackables;
        this.persons = data.trackables
            .filter((value) => value.type === Person.typeName)
            .map((value) => value);
        this.tags = data.tags;
        this.camera = data.camera;
        this.device = data.device;
        this.probabilityIndex = data.probabilityIndex;
        this.undefinedTrackablesCount = data.undefinedTrackablesCount;
        this.correlationId = data.correlationId === null ? undefined : data.correlationId;
    }
}
