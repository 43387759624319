import BaseEditableObject from "./BaseEditableObject";
class EditableTrainingType extends BaseEditableObject {
    constructor(data = {}) {
        super(data);
        this.code = data.code;
        this.name = data.name;
        this.description = data.description;
    }
}
export default EditableTrainingType;
