import Point from "./Point";
import Creatable from "./Creatable";
class ZonePolygon extends Creatable {
    constructor(data) {
        super(data);
        this.height = data.height;
        this.altitude = data.altitude;
        this.versionNumber = data.versionNumber;
        this.points = data.points.map((value) => new Point(value, data.altitude));
    }
}
export default ZonePolygon;
