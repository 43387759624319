import Versioned from "./Versioned";
import TagType from "./TagType";
export default class Tag extends Versioned {
    constructor(tag) {
        super(tag);
        this.statusTransitions = [];
        this.name = tag.name;
        this.vendorId = tag.vendorId;
        this.status = tag.status;
        this.statusTransitions = tag.statusTransitions;
        this.description = tag.description;
        this.externalSystemId = tag.externalSystemId;
        this.trackable = tag.trackable;
        this.tagType = new TagType(tag.tagType);
        this.permanent = tag.permanent;
        this.tagProfileId = tag.tagProfileId;
    }
}
