var PersonAssignmentsEnum;
(function (PersonAssignmentsEnum) {
    /**
     * Зоны
     */
    PersonAssignmentsEnum[PersonAssignmentsEnum["Zones"] = 1] = "Zones";
    /**
     * Нахождению в зонах
     */
    PersonAssignmentsEnum[PersonAssignmentsEnum["ZoneVisits"] = 2] = "ZoneVisits";
    /**
     * Должности ГПН
     */
    PersonAssignmentsEnum[PersonAssignmentsEnum["PositionGPN"] = 3] = "PositionGPN";
    /**
     * Категории зон
     */
    PersonAssignmentsEnum[PersonAssignmentsEnum["ZoneCategories"] = 4] = "ZoneCategories";
    /**
     * Организациям
     */
    PersonAssignmentsEnum[PersonAssignmentsEnum["Organizations"] = 5] = "Organizations";
})(PersonAssignmentsEnum || (PersonAssignmentsEnum = {}));
export default PersonAssignmentsEnum;
