import { locale } from "@dwclient/config";
import { Domain, MetricsEnum, } from "@dwclient/domain";
import ruLocale from "./ru";
import enLocale from "./en";
const resourcesResolver = (locale) => {
    switch (locale) {
        case Domain.ClientLocale.ru:
            return ruLocale;
        case Domain.ClientLocale.en:
            return enLocale;
        default:
            return ruLocale;
    }
};
const resources = resourcesResolver(locale);
export default resources;
export function declOfNum(number, texts) {
    const n = Math.abs(number) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) {
        return texts[2];
    }
    if (n1 > 1 && n1 < 5) {
        return texts[1];
    }
    if (n1 === 1) {
        return texts[0];
    }
    return texts[2];
}
