class Label {
    constructor({ id, name, position, zone, ts }) {
        if (!id) {
            throw new Error("Id is not defined");
        }
        this.id = id;
        this.name = name;
        this.zone = zone;
        this.ts = ts;
        if (!position ||
            position.longitude === undefined ||
            position.latitude === undefined ||
            position.altitude === undefined) {
            throw new Error("Position is not defined for Label id=" + id);
        }
        this.position = {
            latitude: position.latitude,
            longitude: position.longitude,
            altitude: position.altitude,
        };
    }
}
export default Label;
