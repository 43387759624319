var BusinessRuleValueTypeEnum;
(function (BusinessRuleValueTypeEnum) {
    BusinessRuleValueTypeEnum["ZONE_ID"] = "ZONE_ID";
    BusinessRuleValueTypeEnum["TRACKABLE_ID"] = "TRACKABLE_ID";
    BusinessRuleValueTypeEnum["PERSON_ID_BY_PERSON_EXTERNAL_ID"] = "PERSON_ID_BY_PERSON_EXTERNAL_ID";
    BusinessRuleValueTypeEnum["ORGANIZATION_ID_BY_TRACKABLE_ID"] = "ORGANIZATION_ID_BY_TRACKABLE_ID";
    BusinessRuleValueTypeEnum["ORGANIZATION_ID_BY_PERSON_EXTERNAL_ID"] = "ORGANIZATION_ID_BY_PERSON_EXTERNAL_ID";
    BusinessRuleValueTypeEnum["DEPLOYMENT_ID_BY_TRACKABLE_ID"] = "DEPLOYMENT_ID_BY_TRACKABLE_ID";
    BusinessRuleValueTypeEnum["DEPLOYMENT_ID_BY_PERSON_EXTERNAL_ID"] = "DEPLOYMENT_ID_BY_PERSON_EXTERNAL_ID";
    BusinessRuleValueTypeEnum["POSITION_ID_BY_TRACKABLE_ID"] = "POSITION_ID_BY_TRACKABLE_ID";
    BusinessRuleValueTypeEnum["POSITION_ID_BY_PERSON_EXTERNAL_ID"] = "POSITION_ID_BY_PERSON_EXTERNAL_ID";
    BusinessRuleValueTypeEnum["TAG_VENDOR_ID"] = "TAG_VENDOR_ID";
    BusinessRuleValueTypeEnum["EXTERNAL_SYSTEM_ID_BY_TAG_VENDOR_ID"] = "EXTERNAL_SYSTEM_ID_BY_TAG_VENDOR_ID";
    BusinessRuleValueTypeEnum["TAG_TYPE_ID_BY_TAG_VENDOR_ID"] = "TAG_TYPE_ID_BY_TAG_VENDOR_ID";
    BusinessRuleValueTypeEnum["CAMERA_ID"] = "CAMERA_ID";
    BusinessRuleValueTypeEnum["CAMERA_ID_BY_CAMERA_CODE"] = "CAMERA_ID_BY_CAMERA_CODE";
    BusinessRuleValueTypeEnum["GAS_LEVEL"] = "GAS_LEVEL";
    BusinessRuleValueTypeEnum["BATTERY_LEVEL"] = "BATTERY_LEVEL";
    BusinessRuleValueTypeEnum["ZONE_ENTRANCE_TYPE"] = "ZONE_ENTRANCE_TYPE";
    BusinessRuleValueTypeEnum["PROTECTION_EQUIPMENT_TYPE_ID"] = "PROTECTION_EQUIPMENT_TYPE_ID";
    BusinessRuleValueTypeEnum["ZONE_ID_HIERARCHY"] = "ZONE_ID_HIERARCHY";
    BusinessRuleValueTypeEnum["ZONE_ID_BY_CAMERA_ID"] = "ZONE_ID_BY_CAMERA_ID";
    BusinessRuleValueTypeEnum["ZONE_ID_BY_CAMERA_CODE"] = "ZONE_ID_BY_CAMERA_CODE";
    BusinessRuleValueTypeEnum["ZONE_ID_HIERARCHY_BY_CAMERA_ID"] = "ZONE_ID_HIERARCHY_BY_CAMERA_ID";
    BusinessRuleValueTypeEnum["ZONE_ID_HIERARCHY_BY_CAMERA_CODE"] = "ZONE_ID_HIERARCHY_BY_CAMERA_CODE";
    BusinessRuleValueTypeEnum["ZONE_ID_HIERARCHY_BY_TRACKABLE_ID"] = "ZONE_ID_HIERARCHY_BY_TRACKABLE_ID";
    BusinessRuleValueTypeEnum["ZONE_ID_BY_SENSOR_VENDOR_ID"] = "ZONE_ID_BY_SENSOR_VENDOR_ID";
})(BusinessRuleValueTypeEnum || (BusinessRuleValueTypeEnum = {}));
export default BusinessRuleValueTypeEnum;
