import Coords from "./Coords";
import BaseEditableObject from "./BaseEditableObject";
import EditableZone from "./EditableZone";
class EditableZoneModel extends BaseEditableObject {
    constructor({ model, angle, zone, position, name, ...args } = {}, viewPoints, parentZoneTimeUtilization) {
        super(args);
        this.oldIdx = -1;
        this.name = name;
        this.parentZoneTimeUtilization = parentZoneTimeUtilization;
        this.zone = this.oldZone =
            zone && new EditableZone({ zoneTimeUtilization: parentZoneTimeUtilization, ...zone }, viewPoints);
        this.modelFile = model && model.file;
        this.angle = this.oldAngle = angle || 0;
        this.scale = this.oldScale = (model && model.scale) || 1;
        this.position = this.oldPosition =
            position ||
                new Coords({
                    altitude: null,
                    longitude: null,
                    latitude: null,
                });
    }
}
export default EditableZoneModel;
