export default class Beacon {
    constructor(data) {
        this.id = data.id;
        this.ts = data.ts;
        this.name = data.name;
        this.txPower = data.txPower;
        this.mac = data.mac;
        this.position = data.position;
        this.eddystoneEnabled = data.eddystoneEnabled;
        this.eddystoneNamespace = data.eddystoneNamespace;
        this.eddystoneInstance = data.eddystoneInstance;
        this.ibeaconEnabled = data.ibeaconEnabled;
        this.ibeaconUUID = data.ibeaconUUID;
        this.ibeaconMajor = data.ibeaconMajor;
        this.ibeaconMinor = data.ibeaconMinor;
    }
}
