import { createContext } from "react";
import { Observable, Subject } from "rxjs";

type Func<V extends { id: string }> = (ids: string[]) => Promise<V[]>;

type SignleRequest<V extends { id: string }> = {
    func: Func<V>;
    id: string;
};
type SingleResponse<V extends { id: string }> = {
    func: Func<V>;
    values: V[];
};

export class RequestHandler {
    private readonly subjectRequests = new Subject<SignleRequest<any>>();
    private readonly subject = new Subject<SingleResponse<any>>();

    private readonly resultStream = this.subjectRequests
        .groupBy((value) => value.func)
        .flatMap((group) => {
            const func = group.key;
            return group
                .map((value) => value.id)
                .buffer(group.auditTime(200))
                .flatMap((ids) => Observable.fromPromise(func(ids)))
                .map((values) => {
                    return {
                        values,
                        func,
                    };
                });
        })
        .subscribe(this.subject);

    public request<V extends { id: string }>(func: Func<V>, id: string): Promise<V> {
        const promise = (this.subject as Subject<SingleResponse<V>>)
            .filter((req) => req.func === func)
            .flatMap((value) => Observable.from(value.values))
            .find((v) => v.id === id)
            .timeout(4000)
            .toPromise();

        this.subjectRequests.next({
            func,
            id,
        });

        return promise;
    }

    public close() {
        this.resultStream.unsubscribe();
    }
}

const BatchRequestContext = createContext<RequestHandler>(null);

export default BatchRequestContext;
