class TrackableInfo {
    constructor(data) {
        this.trackableObjectId = data.trackableObjectId;
        this.healthStatus = data.healthStatus;
        this.pulse = data.pulse;
        this.signalStatus = data.signalStatus;
        this.temperature = data.temperature;
        this.chargeLevel = data.chargeLevel;
    }
}
export default TrackableInfo;
