var EventAssignmentsEnum;
(function (EventAssignmentsEnum) {
    /**
     * Статус
     */
    EventAssignmentsEnum[EventAssignmentsEnum["Statuses"] = 1] = "Statuses";
    /**
     * Сотрудник
     */
    EventAssignmentsEnum[EventAssignmentsEnum["Persons"] = 2] = "Persons";
    /**
     * Зоны
     */
    EventAssignmentsEnum[EventAssignmentsEnum["Zones"] = 3] = "Zones";
    /**
     * Приоритет
     */
    EventAssignmentsEnum[EventAssignmentsEnum["Priorities"] = 4] = "Priorities";
    /**
     * Тип
     */
    EventAssignmentsEnum[EventAssignmentsEnum["Types"] = 5] = "Types";
})(EventAssignmentsEnum || (EventAssignmentsEnum = {}));
export default EventAssignmentsEnum;
