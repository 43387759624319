import { Paper, Typography } from "@material-ui/core";
import resources from "@dwclient/resources";

export default function ErrorMessage({ error }) {
    return (
        <Paper className="loader">
            <Typography className="header" variant={"h4"}>
                {resources.errors.runtime}
            </Typography>
            <Typography className="message" variant={"body1"} color={"error"}>
                {error}
            </Typography>
        </Paper>
    );
}
