export default class Coords {
    constructor({ longitude, latitude, altitude }) {
        this.longitude = longitude;
        this.latitude = latitude;
        this.altitude = altitude;
    }
    static addCoords(coords1, coords2) {
        return new Coords({
            longitude: coords1.longitude + coords2.longitude,
            latitude: coords1.latitude + coords2.latitude,
            altitude: coords1.altitude + coords2.altitude,
        });
    }
    static subCoords(coords1, coords2) {
        return new Coords({
            longitude: coords1.longitude - coords2.longitude,
            latitude: coords1.latitude - coords2.latitude,
            altitude: coords1.altitude - coords2.altitude,
        });
    }
    static isZero(coords) {
        return (!coords ||
            (coords.longitude === 0 && coords.latitude === 0 && coords.altitude === 0) ||
            coords.longitude === null ||
            coords.latitude === null ||
            coords.altitude === null);
    }
}
