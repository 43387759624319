import * as uuid from "uuid";
import SearchTimezoneWorkerMessage from "./SearchTimezoneWorkerMessage";
import SearchResultWorkerMessage from "./SearchResultWorkerMessage";
import GetByIdResultWorkerMessage from "./GetByIdResultWorkerMessage";
import SerializedWorker from "../SerializedWorker";
import GetTimezoneWorkerMessage from "./GetTimezoneWorkerMessage";
import GuessTimezoneWorkerMessage from "./GuessTimezoneWorkerMessage";

class TimeZoneWorker extends SerializedWorker {
    constructor(timeout: number) {
        super(new Worker(new URL("./timezone.worker.js", import.meta.url)), timeout);
    }

    public findTimeZones(text: string | null) {
        const caller = uuid.v4();
        const promise = this.handleData<SearchResultWorkerMessage>(caller);
        this.sendData(new SearchTimezoneWorkerMessage(text, caller));
        return promise;
    }

    public findById(id: string) {
        const caller = uuid.v4();
        const promise = this.handleData<GetByIdResultWorkerMessage>(caller);
        this.sendData(new GetTimezoneWorkerMessage(id, caller));
        return promise;
    }

    public guessTz() {
        const caller = uuid.v4();
        const promise = this.handleData<GetByIdResultWorkerMessage>(caller);
        this.sendData(new GuessTimezoneWorkerMessage(caller));
        return promise;
    }
}

export default TimeZoneWorker;
