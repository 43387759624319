var DeviceAssignmentsEnum;
(function (DeviceAssignmentsEnum) {
    /**
     * Статус подключения
     */
    DeviceAssignmentsEnum[DeviceAssignmentsEnum["ConnectionStatuses"] = 1] = "ConnectionStatuses";
    /**
     * Показатель
     */
    DeviceAssignmentsEnum[DeviceAssignmentsEnum["Detector"] = 2] = "Detector";
})(DeviceAssignmentsEnum || (DeviceAssignmentsEnum = {}));
export default DeviceAssignmentsEnum;
