/**
 * Канал отправки сообщений
 */
class MessageChannel {
    constructor(id, name, type) {
        this.id = id;
        this.name = name;
        this.type = type;
    }
}
export default MessageChannel;
