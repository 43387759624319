import BaseEditableObject from "./BaseEditableObject";
import EditableEquipment from "./EditableEquipment";
class EditableRoutePoint extends BaseEditableObject {
    constructor(args, viewPoints) {
        super(args);
        this.active = args.active;
        this.equipment = args.equipment && new EditableEquipment(args.equipment, viewPoints);
        this.daysOfWeek = args.daysOfWeek;
        this.shiftIds = args.shiftIds;
        this.orderNumber = args.orderNumber;
        this.startTime = args.startTime;
        this.endTime = args.endTime;
        this.inspectionsNumber = args.inspectionsNumber;
        this.periodicity = args.periodicity;
    }
    static prepareToSave(before, after) {
        if (before && before.equipment !== after.equipment) {
            return {
                ...after,
                equipment: before.equipment
                    ? EditableEquipment.prepareToSave(before.equipment, after.equipment)
                    : after.equipment,
            };
        }
        return after;
    }
}
export default EditableRoutePoint;
