import { WorkPermitType } from "./WorkPermitType";
import PersonLink from "./PersonLink";
import Base from "./Base";
class WorkPermit extends Base {
    constructor(args) {
        super(args);
        this.type = new WorkPermitType(args.type);
        this.description = args.description;
        this.registrationNumber = args.registrationNumber;
        this.registrationDate = args.registrationDate && new Date(args.registrationDate);
        this.startDate = args.startDate && new Date(args.startDate);
        this.endDate = args.endDate && new Date(args.endDate);
        this.createdAt = args.createdAt && new Date(args.createdAt);
        this.foreman = args.foreman && new PersonLink(args.foreman);
        this.persons = args.persons.map((value) => new PersonLink(value));
        this.zoneId = args.zone?.id;
        this.status = args.status;
    }
}
export default WorkPermit;
