import BaseViewPoint from "./BaseViewPoint";
class ViewPoint extends BaseViewPoint {
    constructor(data) {
        const { id, name, ts } = data;
        if (!id) {
            throw new Error("Id is not defined");
        }
        try {
            super(data);
        }
        catch (e) {
            throw new Error(e.message + " for ViewPoint id=" + id);
        }
        this.id = id;
        this.name = name;
        this.ts = ts || 1;
    }
}
export default ViewPoint;
